/**
 * Infinite Scroll directive
 *
 * params:
 * 	loadMore: function
 *  distance: number
 *  immediate: boolean
 * 	throttle: number
 *
 */

import Vue from "vue";

Vue.directive("infinite-scroll", {
  bind(el, binding) {
    let throttleTimer;

    const loadMore = () => {
      if (typeof binding.value.loadMore === "function") {
        binding.value.loadMore();
      }
    };

    const scrollHandler = () => {
      if (throttleTimer) return;

      throttleTimer = setTimeout(() => {
        const rect = el.getBoundingClientRect();
        const isAtBottom =
          rect.bottom <=
          window.innerHeight + Number(binding.value.distance || 0);
        if (isAtBottom) {
          loadMore();
        }

        throttleTimer = null;
      }, binding.value.throttle || 200); // Throttle time
    };

    if (binding.value.immediate) {
      loadMore();
    }

    window.addEventListener("scroll", scrollHandler);

    el._scrollHandler = scrollHandler;
  },

  unbind(el) {
    window.removeEventListener("scroll", el._scrollHandler);
    delete el._scrollHandler;
  },
});

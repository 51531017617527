
import { InputControl } from "~/components/ui/input";
export default {
  name: "TextSearchTab",
  components: { InputControl },
  props: ["value"],
  computed: {
    state: {
      get() {
        return this.value;
      },
      set(state) {
        this.$emit("input", { ...this.value, ...state });
      },
    },
  },
};


export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    footerAlign: {
      type: String,
      default: "right", // left, center, right
    },
  },

  data() {
    return {
      uid: Math.random().toString(36).substring(2, 15),
      pagePaddingRight: 0,
    };
  },

  computed: {
    isMobile() {
      return this.$store.state.global.mobile;
    },
  },

  watch: {
    isOpen: {
      handler() {
        if (!process.browser) return;
        if (this.isOpen) {
          this.lockScroll();
        } else {
          this.unlockScroll();
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.unlockScroll();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    lockScroll() {
      if (document.body.parentNode.dataset.cModalUid) return;
      document.body.parentNode.dataset.cModalUid = this.uid;
      document.body.parentNode.style.overflowY = "hidden";
      if (this.isMobile) return;
      this.pagePaddingRight = document.body.parentNode.style.paddingRight;
      document.body.parentNode.style.paddingRight = "5px";
    },

    unlockScroll() {
      if (document.body.parentNode.dataset.cModalUid !== this.uid) return;
      delete document.body.parentNode.dataset.cModalUid;
      document.body.parentNode.style.overflowY = "auto";
      if (this.isMobile) return;
      document.body.parentNode.style.paddingRight = this.pagePaddingRight;
    },
  },
};

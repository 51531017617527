// middleware/redirects.js

export default function (req, res, next) {
  const { host } = req.headers;
  const baseUrl = process.env.BASE_URL_EMIRATE;

  if (
    [
      "/index.asp",
      "/index.aspx",
      "/index.htm",
      "/index.html",
      "/index.php",
    ].includes(req.url.toLowerCase())
  ) {
    res.writeHead(301, { Location: baseUrl });
    res.end();
    return;
  }

  if (host.startsWith("www.")) {
    res.writeHead(301, { Location: `${baseUrl}${req.url}` });
    res.end();
    return;
  }

  next();
}

import { render, staticRenderFns } from "./profileNav.vue?vue&type=template&id=0b1dd59c"
import script from "./profileNav.vue?vue&type=script&lang=js"
export * from "./profileNav.vue?vue&type=script&lang=js"
import style0 from "./profileNav.vue?vue&type=style&index=0&id=0b1dd59c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMessage: require('/app/components/icons/IconMessage.vue').default,IconShare: require('/app/components/icons/IconShare.vue').default,IconReport: require('/app/components/icons/IconReport.vue').default,IconBookMe: require('/app/components/global/IconBookMe.vue').default,VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})

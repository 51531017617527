export const state = () => ({
  messageList: [],

  currentPage: 1,
  amountMessage: 0,
  lastPage: false,
  perPage: 20,

  currentPageSupport: 1,

  date_from: "",
  date_to: "",
  searchText: "",
});

export const getters = {
  getMessages: (state) => state.messageList,

  getLastPage: (state) => state.lastPage,

  getAmountMessages: (state) => state.amountMessage,

  getCurrentSupportPage: (state) => state.currentPageSupport,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setMessages(state, list) {
    state.messageList = list;
  },

  setUploadMessages(state, list) {
    state.messageList = [...state.messageList, ...list];
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setAmountMessage(state, value) {
    state.amountMessage = value;
  },

  setCurrentPage(state, value) {
    state.currentPage = value;
  },

  setSupportPage(state, value) {
    state.currentPageSupport = value;
  },

  setDatesFilter(state, dates) {
    if (!dates.length) {
      state.date_from = "";
      state.date_to = "";
    } else if (dates.length === 1) {
      state.date_from = dates[0];
      state.date_to = "";
    } else {
      state.date_from = dates[0];
      state.date_to = dates[1];
    }
  },

  setSearchText(state, value) {
    state.searchText = value;
  },

  setNewMessagesById(state, payload) {
    for (let i = 0; i < state.messageList.length; i++) {
      if (state.messageList[i].id === payload.id) {
        state.messageList[i].new_messages = payload.new_messages;
        return;
      }
    }
  },

  setIsNewById(state, payload) {
    for (let i = 0; i < state.messageList.length; i++) {
      if (state.messageList[i].id === payload.id) {
        state.messageList[i].isNew = payload.isNew;
        return;
      }
    }
  },
};

export const actions = {
  async retrieveMessagesInSupport({ state, commit }) {
    commit("setCurrentPage", 1);

    try {
      const { data } = await this.$axios.get("/api/support", {
        params: {
          page: state.currentPage,
          active: state.currentPageSupport,
          date_from: state.date_from,
          date_to: state.date_to,
          search: state.searchText,
          per_page: state.perPage,
        },
      });

      commit("setMessages", data?.data || []);

      commit("setAmountMessage", data?.total || 0);
      if (data?.current_page || 0 < data?.last_page || 0) {
        commit("setCurrentPage", state.currentPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }
    } catch (error) {
      commit("setMessages", []);
      console.loh(error);
    }
  },

  async uploadMessagesInSupport({ state, commit }) {
    try {
      const { data } = await this.$axios.get("/api/support", {
        params: {
          page: state.currentPage,
          active: state.currentPageSupport,
          date_from: state.date_from,
          date_to: state.date_to,
          search: state.searchText,
          per_page: state.perPage,
        },
      });

      commit("setUploadMessages", data.data);

      commit("setAmountMessage", data.total);
      if (data.current_page < data.last_page) {
        commit("setCurrentPage", state.currentPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }
    } catch (error) {
      commit("setUploadMessages", []);
      console.log(error);
    }
  },

  async readSupport(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "support",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },

  async readSupportSubject(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "support-subject",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },
};

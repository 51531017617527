export const advancedTabData = {
  checkboxes: [
    { label: "With video", key: "withVideo" },
    { label: "Quality Mark", key: "qualityMark" },
    { label: "With reviews", key: "withReviews" },
    { label: "Show only single profiles", key: "singleProfiles" },
    { label: "Has natural photo", key: "naturalPhoto" },
    { label: "Verified", key: "verified" },
  ],
  selectList: [], // fill this array with data from the server
  serviceFor: [
    { label: "Men", key: "1" },
    { label: "Women", key: "2" },
    { label: "Couples", key: "3" },
  ],
};

import { render, staticRenderFns } from "./private-mess-modal.vue?vue&type=template&id=ed85bdea&scoped=true"
import script from "./private-mess-modal.vue?vue&type=script&lang=js"
export * from "./private-mess-modal.vue?vue&type=script&lang=js"
import style0 from "./private-mess-modal.vue?vue&type=style&index=0&id=ed85bdea&prod&lang=scss"
import style1 from "./private-mess-modal.vue?vue&type=style&index=1&id=ed85bdea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed85bdea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default,VTextarea: require('/app/node_modules/vuetify/lib/components/VTextarea/VTextarea.js').default,IconMessageSend: require('/app/components/icons/IconMessageSend.vue').default,VForm: require('/app/node_modules/vuetify/lib/components/VForm/VForm.js').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VForm,VIcon,VTextarea})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})


export default {
  name: "MySwitch",
  props: {
    value: {
      // type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "My Switch",
    },
    labelPosition: {
      type: String,
      default: "left", // left, right
    },
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
  },

  emits: ["change"],

  methods: {
    onChange(e) {
      const value = e.target.checked ? this.trueValue : this.falseValue;
      this.$emit("input", value);
    },
  },
};

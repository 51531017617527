
import { FormControl } from "~/components/ui/form-control";

export default {
  name: "SelectControl",
  components: { FormControl },
  props: ["value", "items", "label", "placeholder", "className"],
  data() {
    return {
      defaultValue: {},
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value || this.defaultValue;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
  mounted() {
    this.items.forEach((item) => {
      if (item.selected) this.defaultValue = item;
    });
  },
};

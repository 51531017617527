export const state = () => ({
  allItems: [],
  page: 1,
  perPage: 20,
  total: 0,

  selectedMessage: [],
  searchText: "",
});

export const getters = {
  getItems: (state) => state.allItems,

  getTotal: (state) => state.total,

  getSelectedMessage: (state) => state.selectedMessage,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setItems: (state, items) => {
    state.allItems = items;
  },

  setTotal: (state, numbers) => {
    state.total = numbers;
  },

  setPage: (state, number) => {
    state.page = number;
  },

  setSelectAllMessage(state, data) {
    state.selectedMessage = data;
  },

  setSelectOneMessage(state, id) {
    if (state.selectedMessage.includes(id)) {
      state.selectedMessage = state.selectedMessage.filter((el) => el !== id);
    } else {
      state.selectedMessage.push(id);
    }
  },

  setSearchText(state, value) {
    state.searchText = value;
  },

  setDialogNewMessages(state, payload) {
    for (let i = 0; i < state.allItems.length; i++) {
      if (state.allItems[i].id === payload.id) {
        state.allItems[i].new_messages = payload.new_messages;
        break;
      }
    }
  },
};

export const actions = {
  async retrieveItems({ state, commit }, page) {
    try {
      let response = await this.$axios.$get("/api/messages/manager/get", {
        params: {
          page: page || state.page,
          search: state.searchText,
          per_page: state.perPage,
        },
      });

      if (page) {
        commit("setItems", response.dialogs);
      } else {
        commit("setItems", [...state.allItems, ...response.dialogs]);
      }

      commit("setPage", response.page + 1);
      commit("setTotal", response.total);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },
};

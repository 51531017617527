
import HeaderV2 from "~/components/v2/common/header/header-v2.vue";

export default {
  components: {
    HeaderV2,
  },

  middleware: ["isActive", "processSplashFilters"],

  head() {
    return {
      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Emiratescort",
            url: this.$config.baseUrlApp,
            logo: `${this.$config.baseUrlApp}/Symmetrical-logo.png`,
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+380950000000",
              contactType: "customer service",
              areaServed: "AE",
              availableLanguage: "en",
            },
          }),
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
    };
  },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }

    // this.$root.$on("set-page-padding", () => {
    //   this.setPagePadding();
    // });
  },

  // methods: {
  //   setPagePadding() {
  //     if (!this.$refs.header) return;
  //     this.headerHeight =
  //       this.$refs.header.$el.querySelector("header").clientHeight;
  //   },
  // },
};

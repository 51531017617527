export const state = () => ({
  profiles: [],

  lastPage: false,
  numberProfiles: 0,
  nextPage: 1,

  sortedParams: {
    orderDirection: "desc",
    orderName: "created_at",
  },

  searchText: "",
  deleted: null,
  qm: null,
  pornstar: null,
});

export const getters = {
  getProfiles: (state) => state.profiles,

  getLastPage: (state) => state.lastPage,

  getNumberProfiles: (state) => state.numberProfiles,

  getOrderDirection: (state) => state.sortedParams.orderDirection,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setProfiles(state, data) {
    state.profiles = data;
  },

  setUploadProfiles(state, profiles) {
    state.profiles = [...state.profiles, ...profiles];
  },

  setNextPage(state, value) {
    state.nextPage = value;
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setNumberProfiles(state, number) {
    state.numberProfiles = number;
  },

  setOrderDirection(state, value) {
    state.sortedParams.orderDirection = value;
  },

  setOrderName(state, value) {
    state.sortedParams.orderName = value;
  },

  setSearchText(state, text) {
    state.searchText = text;
  },

  setDeleted(state, value) {
    state.deleted = value;
  },

  switchFilter(state, value) {
    state.qm = null;
    state.deleted = null;
    if (value !== "all") {
      state[value] = true;
    }
  },
};

export const actions = {
  async retrieveProfiles({ state, commit }, params) {
    commit("setNextPage", 1);

    try {
      const { data } = await this.$axios.get(`/api/profiles/manager`, {
        params: {
          per_page: 100,
          page: state.nextPage,
          order: `${state.sortedParams.orderName}-${state.sortedParams.orderDirection}`,
          search: state.searchText,
          deleted: state.deleted,
          qm: state.qm,
          ...params,
        },
      });

      if (data.page < data.number_pages) {
        commit("setLastPage", false);
        commit("setNextPage", state.nextPage + 1);
      } else {
        commit("setLastPage", true);
      }

      commit("setNumberProfiles", data.total);
      commit("setProfiles", data.profiles);
    } catch (error) {
      commit("setProfiles", []);
      console.log(error);
    }
  },

  async uploadProfiles({ state, commit }) {
    try {
      const { data } = await this.$axios.get(
        `/api/profiles/manager?per_page=100&page=${state.nextPage}&order=${state.sortedParams.orderName}-${state.sortedParams.orderDirection}&search=${state.searchText}`
      );

      if (data.page < data.number_pages) {
        commit("setNextPage", state.nextPage + 1);
      } else {
        commit("setLastPage", true);
      }

      commit("setNumberProfiles", data.total);
      commit("setUploadProfiles", data.profiles);
    } catch (error) {
      commit("setUploadProfiles", []);
      console.log(error);
    }
  },
};

export const state = () => ({
  blackLists: [],
  page: 1,
  lastPage: false,
  numbersLists: 0,
  searchText: "",

  reasonList: {},
});

export const getters = {
  getBlackLists: (state) => state.blackLists,

  getLastPage: (state) => state.lastPage,

  getNumbersLists: (state) => state.numbersLists,

  getReasonList: (state) => state.reasonList,
};

export const mutations = {
  setBlackLists(state, lists) {
    state.blackLists = lists;
  },

  addBlackLists(state, lists) {
    state.blackLists = [...state.blackLists, ...lists];
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setNumbersLists(state, numbers) {
    state.numbersLists = numbers;
  },

  setNextPage(state, value) {
    state.page = value;
  },

  setReasonList(state, value) {
    state.reasonList = value;
  },

  setSearchText(state, value) {
    state.searchText = value;
  },
};

export const actions = {
  async retrieveBlackLists({ state, commit }) {
    try {
      commit("setNextPage", 1);

      const { data } = await this.$axios.get(
        `/api/blacklist?page=${state.page}&search=${state.searchText}`
      );
      const { data: reason } = await this.$axios.get("/api/blacklist/types");

      commit("setReasonList", reason);
      commit("setNumbersLists", data.total);
      commit("setBlackLists", data.blacklist);

      if (data.page < data.number_pages) {
        commit("setLastPage", false);
        commit("setNextPage", ++data.page);
      } else {
        commit("setLastPage", true);
      }
    } catch (error) {
      commit("setBlackLists", []);
      console.log(error);
    }
  },

  async uploadBlackLists({ state, commit }) {
    try {
      const { data } = await this.$axios.get(
        `/api/blacklist?page=${state.page}&search=${state.searchText}`
      );

      commit("addBlackLists", data.blacklist);

      if (data.page < data.number_pages) {
        commit("setLastPage", false);
        commit("setNextPage", ++data.page);
      } else {
        commit("setLastPage", true);
      }
    } catch (error) {
      console.log(error);
    }
  },
};


function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

import { routeToCrumbs } from "~/common/helpers.js";
export default {
  name: "Breadcrumbs",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  head() {
    return {
      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(this.jsonld),
        },
      ],

      __dangerouslyDisableSanitizers: ["script"],
    };
  },

  computed: {
    crumbs() {
      if (this.items.length) return this.items;
      return routeToCrumbs(this.$route.fullPath);
    },

    jsonld() {
      return {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: this.crumbs.map((crumb, index) => ({
          "@type": "ListItem",
          position: index + 1,
          item: {
            "@id": `${this.$config.baseUrlApp}${crumb.href}`,
            name: capitalize(crumb.text),
          },
        })),
      };
    },
  },
};

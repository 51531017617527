export default ({ store }, inject) => {
  function notify(notification) {
    if (typeof notification === "string") {
      notification = {
        message: notification,
      };
    }
    store.dispatch("c-notifications/addNotification", notification);
  }

  inject("notify", notify);
};

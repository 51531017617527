
import Checkbox, { props } from "./checkbox";
import { FormControl } from "../form-control";

export default {
  name: "CheckboxControl",
  components: { Checkbox, FormControl },
  props,
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
};

import { render, staticRenderFns } from "./user-panel.vue?vue&type=template&id=5d833dac&scoped=true"
import script from "./user-panel.vue?vue&type=script&lang=js"
export * from "./user-panel.vue?vue&type=script&lang=js"
import style0 from "./user-panel.vue?vue&type=style&index=0&id=5d833dac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d833dac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default,IconProfileMenu: require('/app/components/icons/IconProfileMenu.vue').default,IconLogoutMenu: require('/app/components/icons/IconLogoutMenu.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})

export const state = () => ({
  sidebarNotifications: {},
});

export const getters = {
  getSidebarNotifications: (state) => state.sidebarNotifications,
};

export const mutations = {
  setSidebarNotifications(state, data) {
    state.sidebarNotifications = data;
  },

  decreaseSidebarNotificationsByKey(state, payload) {
    state.sidebarNotifications[payload.key] -= payload.value;
  },

  resetOneNotification(state, value) {
    for (let key in state.sidebarNotifications) {
      if (key === value) state.sidebarNotifications[key] = 0;
    }
  },
};

export const actions = {
  async retrieveSidebarNotifications({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/notifications/get");

      console.log(data);
      commit("setSidebarNotifications", data);
    } catch (error) {
      commit("setSidebarNotifications", {});
      console.log(error);
    }
  },
};

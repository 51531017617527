
import { mapGetters, mapMutations, mapActions } from "vuex";
import userPanel from "~/components/dashboard/user-panel.vue";
import { bindDiscreetModeWithLocalstorage } from "~/store/index";
import SearchModal from "~/components/catalog/search-modal/search-modal";
import {
  objectToArrayOfObjects,
  setSelectedOptions,
  debounce,
  scrollIntoViewWithOffset,
} from "~/common/helpers";
import { mdiCheck, mdiTune, mdiClose } from "@mdi/js";
import IconCatalogCol1 from "~/components/icons/IconCatalogCol1.vue";
import IconCatalogCol2 from "~/components/icons/IconCatalogCol2.vue";
import IconListMobile from "~/components/icons/IconListMobile.vue";

function keysCount(obj = {}, ignore = []) {
  return Object.keys(obj).filter((key) => !ignore.includes(key)).length;
}

const defaultInitDisplayOptions = {
  showLogo: true,
  showNav: true,
  showCity: true,
  showUserPanel: true,
  showBottomPart: true,
  bg: "dark", // default, transparent
};

const defaultOnScrollDisplayOptions = {
  showLogo: true,
  showNav: true,
  showCity: true,
  showUserPanel: true,
  showBottomPart: false,
  bg: "dark", // default, transparent
};

const defaultScrollOffsets = {
  main: 50,
  filters: 150,
};

const defaultForm = {
  withVideo: "0",
  withReviews: "0",
  qualityMark: "0",
  has_natural_photo: "0",
  verified: false,
  showOnlySingleProfiles: false,
  service: [],
  nationality: [],
  age: [],
  hair_color: [],
  budget: [],
  district: [],
};

export default {
  components: {
    userPanel,
    SearchModal,
    IconCatalogCol1,
    IconCatalogCol2,
    IconListMobile,
  },

  layout: "default",

  props: {
    useAdvancedFilters: {
      type: Boolean,
      default: false,
    },
    useCatalogControls: {
      type: Boolean,
      default: false,
    },
    initDisplayOptions: {
      type: Object,
      default: () => defaultInitDisplayOptions,
    },
    onScrollDisplayOptions: {
      type: Object,
      default: () => defaultOnScrollDisplayOptions,
    },
    scrollOffsetsOptions: {
      type: Object,
      default: () => defaultScrollOffsets,
    },
  },

  data() {
    return {
      mdiCheck,
      mdiTune,
      mdiClose,

      selectedTest: [],

      isScrolled: false,
      isShowModalSendMessage: false,
      isPmToManager: false,
      isShowLeftDrawer: false,

      isShowProfileDrawer: false,
      isShowProfileDrawerBtn: false,

      isShowDashboardDrawer: false,
      isShowDashboardDrawerBtn: false,

      reportProfileModal: {
        isOpen: false,
        profile: null,
      },

      pmProfile: null,

      isFavoriteProcessing: false,

      /* --------------- Filters state --------------- */

      valueToOpenHeader: 150,
      isOpenHeaderFilters: false,
      isOpenFiltersBlock: false,
      showAdvancedSearchModal: false,
      tabToOpenSearchModal: "advanced",

      sortOptions: [
        {
          title: this.$t("age"),
          value: "age",
        },
        {
          title: this.$t("bust-size"),
          value: "boobs",
        },
        {
          title: this.$t("hair-color"),
          value: "color",
        },
        {
          title: this.$t("date-added"),
          value: "newest",
        },
      ],
      form: { ...defaultForm },

      isShowMobileFilters: false,
      isTextSearchModalOpen: false,
      isShowMoreFilters: false,
      isShowMainPageMobileFilters: false,
    };
  },

  fetch() {
    if (this.useAdvancedFilters && !Object.keys(this.filtersList).length) {
      this.$store.dispatch("profiles/retrieveFilters");
    }
    if (!this.isCatalog) {
      const location = this.isMain ? "dubai" : this.currentLocationUrl;
      this.$store.dispatch("menus/retrieveMainFilters", `${location}/female`);
    }
  },

  computed: {
    ...mapGetters({
      discreetMode: "getDiscreetStatus",
      profile: "profiles/getProfile",
      currentCity: "global/getCurrentCity",
      currentDistrict: "global/getCurrentDistrict",
      mobileSlug: "global/getMobileSlug",
      headerNavGetter: "menus/getHeaderNav",
      footerNav: "menus/getFooterNav",
      filtersList: "profiles/getFiltersList",
      filters: "profiles/getFilters",
      getDefaultFilters: "profiles/getDefaultFilters",
      menu: "dashboardMenu/getMenuItems",
      notifications: "notifications/getSidebarNotifications",
      catalogMode: "catalog/getCatalogMode",
      catalogMinCol: "catalog/getMinCol",
      currentLocationUrl: "global/getCurrentLocationUrl",
    }),

    isMainPage() {
      return this.$route.name == "index";
    },

    statusNavUrlPrefix() {
      return this.isMainPage ? "/dubai/female" : "";
    },

    services() {
      if (
        this.profile?.serviceDetails &&
        typeof this.profile?.serviceDetails[0]?.service == "object"
      ) {
        return Object.keys(this.profile?.serviceDetails[0].service);
      } else {
        return [];
      }
    },

    headerNav() {
      return this.headerNavGetter(this.$route);
    },

    initDO() {
      return {
        ...defaultInitDisplayOptions,
        ...this.initDisplayOptions,
      };
    },

    onScrollDO() {
      return {
        ...defaultOnScrollDisplayOptions,
        ...this.onScrollDisplayOptions,
      };
    },

    scrollOO() {
      return {
        ...defaultScrollOffsets,
        ...this.scrollsOffsets,
      };
    },

    isShowLogo() {
      return this.isScrolled ? this.onScrollDO.showLogo : this.initDO.showLogo;
    },

    isShowNav() {
      return this.isScrolled ? this.onScrollDO.showNav : this.initDO.showNav;
    },

    isShowCity() {
      return this.isScrolled ? this.onScrollDO.showCity : this.initDO.showCity;
    },

    isShowUserPanel() {
      return this.isScrolled
        ? this.onScrollDO.showUserPanel
        : this.initDO.showUserPanel;
    },

    isShowBottomPart() {
      return this.isScrolled
        ? this.onScrollDO.showBottomPart
        : this.initDO.showBottomPart;
    },

    isBottomPartHidden() {
      return this.isScrolled
        ? !this.onScrollDO.showBottomPart
        : !this.initDO.showBottomPart;
    },

    isShowFiltersMenu() {
      return this.isCatalog;
    },

    isCatalog() {
      return this.$route.name.includes("catalog");
    },

    isMain() {
      return this.$route.name == "index";
    },

    isOnScrollEnabled() {
      return Object.keys(this.onScrollDO).reduce((acc, key) => {
        return (
          acc ||
          (typeof this.onScrollDO[key] === "boolean" && this.onScrollDO[key])
        );
      }, false);
    },

    bg() {
      if (this.isShowMainPageMobileFilters) return "bg-dark";
      return "bg-" + (this.isScrolled ? this.onScrollDO.bg : this.initDO.bg);
    },

    // using to show available links in dashboard menu
    tariffActions() {
      return this.$store.state.auth.user.tariff_actions;
    },

    role() {
      return this.$store?.state?.auth?.user?.user_role;
    },

    menuItems() {
      return this.menu[this.role] || [];
    },

    messageProfile() {
      return this.isPmToManager ? {} : this.pmProfile || this.profile;
    },

    /* --------------- Filters options --------------- */

    servicesSelectOptions() {
      const service = this.filters.service;
      const services = objectToArrayOfObjects(this.filtersList.services);
      return setSelectedOptions(services, service);
    },
    nationalitySelectOptions() {
      return Object.keys(this.filtersList?.nationality_new || {}).map((key) => {
        return {
          value: key,
          title: this.filtersList.nationality_new[key].title,
        };
      });
    },
    ageSelectOptions() {
      return objectToArrayOfObjects(this.filtersList.age);
    },
    hairsSelectOptions() {
      return (
        this.filtersList?.hair_color?.map((hc) => {
          return {
            title: hc.title,
            value: `${hc.id}`,
          };
        }) || []
      );
    },
    budgetSelectOptions() {
      return objectToArrayOfObjects(this.filtersList.budget);
    },
    areaSelectOptions() {
      let districts = [];
      if (!this.filtersList || !this.filtersList.districts) {
        return [];
      }

      for (let i = 0; i < this.filtersList.districts.length; i++) {
        let district = this.filtersList.districts[i];
        let cityId = this.isMain ? 1 : this.currentCity.id;
        if (district.city_id == cityId) {
          districts.push({
            title: district.title,
            value: "" + district.id,
          });
        }
      }

      return districts;
    },

    selectList() {
      return [
        {
          placeholder: this.$t("service"),
          options: this.servicesSelectOptions,
          filterName: "service",
        },
        {
          placeholder: this.$t("nationality"),
          options: this.nationalitySelectOptions,
          filterName: "nationality",
        },
        {
          placeholder: this.$t("age"),
          options: this.ageSelectOptions,
          filterName: "age",
        },
        {
          placeholder: this.$t("hair-color"),
          options: this.hairsSelectOptions,
          filterName: "hair_color",
        },
        {
          placeholder: this.$t("budget"),
          options: this.budgetSelectOptions,
          filterName: "budget",
        },
        {
          placeholder: this.$t("area"),
          options: this.areaSelectOptions,
          filterName: "district",
        },
      ];
    },

    isFiltersApplied() {
      return (
        keysCount(this.filters, [
          "per_page",
          "page",
          "OrderDirection",
          "city",
          "gender",
          "area",
          "new",
          "awaitingCall",
          "qualityMark",
          "verified",
          "incall",
          "outcall",
          "inTour",
        ]) >= 1
      );
    },

    querySlug() {
      return this.$route.query.slug;
    },
  },

  watch: {
    $route(to) {
      this.isShowDashboardDrawerBtn = to.name.startsWith("dashboard");
      this.isShowProfileDrawerBtn = to.name == "profile-slug";
    },
    filters: {
      handler(val) {
        this.form = { ...defaultForm };
        Object.keys(val).forEach((key) => {
          if (this.form[key] !== undefined) {
            const value = val[key];
            if (this.selectList.some((select) => select.filterName === key)) {
              this.form[key] = value ? `${value}`.split(",") : [];
            } else {
              this.form[key] = value;
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },

  beforeCreate() {
    bindDiscreetModeWithLocalstorage(this.$store);
  },

  beforeMount() {
    window.addEventListener("scroll", this.effectScroll, {
      passive: true,
    });
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
      window.removeEventListener("scroll", this.effectScroll);
    }
    this.unlockScroll();
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    this.isShowDashboardDrawerBtn = this.$route.name.startsWith("dashboard");
    this.isShowProfileDrawerBtn = this.$route.name == "profile-slug";

    this.$root.$on("sendPM", (profile, toManager = false) =>
      this.sendPM(profile, toManager)
    );

    this.$root.$on("openReportProfileModal", (profile) => {
      this.reportProfileModal.isOpen = true;
      this.reportProfileModal.profile = profile;
    });
    this.$root.$on("closeReportProfileModal", () => {
      this.reportProfileModal.isOpen = false;
      this.reportProfileModal.profile = null;
    });

    if (this.role && this.role == 1) {
      console.log("CONNECT TO SOCKET HERE");

      if (
        this.$auth?.user?.user_role == 1 &&
        "serviceWorker" in window.navigator
      ) {
        window.navigator.serviceWorker.ready.then(
          (serviceWorkerRegistration) => {
            const beamsClient = new PusherPushNotifications.Client({
              instanceId: this.$config.clientIdPusherManager,
              serviceWorkerRegistration: serviceWorkerRegistration,
            });
            console.log("beamsClient", beamsClient);

            beamsClient
              .start()
              .then((beamsClient) => beamsClient.getDeviceId())
              .then((deviceId) =>
                console.log(
                  "Successfully registered with Beams. Device ID:",
                  deviceId
                )
              )
              .then(() => {
                beamsClient.addDeviceInterest("hello");
                beamsClient.addDeviceInterest(`messages-${this.$auth.user.id}`);
              })
              .then(() => beamsClient.getDeviceInterests())
              .then((interests) => console.log("Current interests:", interests))
              .catch(console.error);
          }
        );
      }
    }
  },

  methods: {
    ...mapMutations({
      setCatalogMode: "catalog/setCatalogMode",
      setMinCol: "catalog/setMinCol",
    }),

    ...mapActions({
      addToFafourites: "profiles/addToFafourites",
      removeFromFavourites: "profiles/removeFromFavourites",
    }),

    processRightDashMenuLinks(link) {
      if (link == "/dashboard/my-profiles" && this.querySlug) {
        return `${link}#${this.querySlug}`;
      }
      return link;
    },

    switchDashMenu() {
      this.isShowDashboardDrawer = !this.isShowDashboardDrawer;
      this.fixIOSicons();
    },

    fixIOSicons() {
      // weird bug with ios safari
      // when you open drawer icons are not shown sometimes
      setTimeout(() => {
        let svgs = document.querySelectorAll(".v-navigation-drawer svg");
        svgs.forEach((svg) => {
          svg.style.display = "none";
          svg.style.display = "block";
        });
      }, 100);
    },

    async processFavorite() {
      this.isFavoriteProcessing = true;
      let response;
      if (this.profile.isFavorite) {
        response = await this.removeFromFavourites(this.profile.id);
      } else {
        response = await this.addToFafourites(this.profile.id);
      }

      if (!response.data.status) {
        this.$notify({
          type: "warning",
          message: response.data.msg,
        });
      }

      this.isFavoriteProcessing = false;
    },

    async resetFilters() {
      await this.$store.dispatch("profiles/resetFilters", true);
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
      this.closeMobileFilters();
      this.isShowLeftDrawer = false;
    },

    applyFilters() {
      this.$store.commit("profiles/setIsForwardedFromMain", true);
      this.$router.push({
        path: "/dubai/female",
      });
    },

    changeDiscreetMode() {
      this.$store.commit("setDiscreet");
    },

    onResize() {
      this.$store.commit("global/setMobile", window.innerWidth < 992);
    },

    sendPM(profile, toManager = false) {
      this.pmProfile = profile;
      this.isShowModalSendMessage = true;
      this.isShowProfileDrawer = false;
      this.isPmToManager = toManager;
    },

    closeModalSendMessage() {
      this.isShowModalSendMessage = false;
      this.isPmToManager = false;
    },

    openedModal() {
      this.$store.commit("global/setModalChangeCityStatus");
    },

    processBottomPartOnScroll() {
      if (!this.isOnScrollEnabled) return;
      const isScrolled = window.scrollY > 50;

      if (isScrolled !== this.isScrolled) {
        this.isScrolled = isScrolled;
        this.$store.commit("global/setPaddingForHiddenHeader", this.isScrolled);
      }
    },

    /* --------------- Filters methods --------------- */

    effectScroll() {
      let pxlScrolled = Math.floor(window.scrollY);
      this.isOpenHeaderFilters = pxlScrolled > this.valueToOpenHeader;
      this.processBottomPartOnScroll();
    },

    openFiltersBlock() {
      this.isOpenFiltersBlock = !this.isOpenFiltersBlock;
    },

    openAdvancedSearchModal(tabToOpen = "advanced") {
      this.showAdvancedSearchModal = true;
      this.tabToOpenSearchModal = tabToOpen;
    },

    onAdvancedSearchModalClose() {
      this.showAdvancedSearchModal = false;
      this.isShowMobileFilters = false;
    },

    onCloseAdvancedSearch() {
      this.showAdvancedSearchModal = false;
    },

    onFilterChange(filter, filterName) {
      const value = filter.value ? filter.value : filter;
      this.$store.commit("profiles/setFilters", {
        [filterName]: value,
      });
      if (!this.isMainPage) {
        this.fetchProfilesDebounced();
      }
    },

    fetchProfilesDebounced: debounce(function () {
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
    }, 500),

    async setSort(value, sortOption) {
      this.onFilterChange(sortOption, "Sort");
    },

    viewSwitcher(value) {
      this.setCatalogMode(value);
    },

    setCatalogGrigCol(minCol) {
      this.setCatalogMode("grid");
      this.setMinCol(minCol);
    },

    async changeDirectionOrder() {
      const order = this.filters.OrderDirection === "desc" ? "asc" : "desc";
      this.onFilterChange(order, "OrderDirection");
    },

    openLeftDrawer() {
      this.isShowLeftDrawer = true;
      this.closeMobileFilters();
    },

    lockScroll() {
      document.body.parentNode.style.overflowY = "hidden";
    },

    unlockScroll() {
      document.body.parentNode.style.overflowY = "auto";
    },

    showMobileFilters() {
      this.isShowLeftDrawer = false;
      this.isShowMobileFilters = true;
      this.lockScroll();
    },

    closeMobileFilters() {
      this.isShowMobileFilters = false;
      this.unlockScroll();
    },

    toggleMobileFilters() {
      this.isShowMobileFilters
        ? this.closeMobileFilters()
        : this.showMobileFilters();
    },

    showMainPageMobileFilters() {
      this.isShowLeftDrawer = false;
      this.isShowMainPageMobileFilters = true;
      this.lockScroll();
    },

    closeMainPageMobileFilters() {
      this.isShowMainPageMobileFilters = false;
      this.unlockScroll();
    },

    toggleMainPageMobileFilters() {
      this.isShowMainPageMobileFilters
        ? this.closeMainPageMobileFilters()
        : this.showMainPageMobileFilters();
    },

    closeTextSearchModal() {
      this.isTextSearchModalOpen = false;
    },

    openTextSearchModal() {
      this.isTextSearchModalOpen = true;
    },

    toggleMoreFilters() {
      this.isShowMoreFilters = !this.isShowMoreFilters;
    },

    goSection(hash) {
      scrollIntoViewWithOffset(hash, 65);
      this.isShowProfileDrawer = false;
    },
  },
};

export const state = () => ({
  allItems: [],
  nextPage: 1,
  lastPage: false,
  numbersItems: 0,

  currentPageNews: "manager",
  selectedNews: [],

  cityForWebsite: {},
});

export const getters = {
  getItems: (state) => state.allItems,

  getLastPage: (state) => state.lastPage,

  getNumbersItems: (state) => state.numbersItems,

  getCurrentPageNews: (state) => state.currentPageNews,

  getSelectedNews: (state) => state.selectedNews,

  getSityForWebsite: (state) => state.cityForWebsite,
};

export const mutations = {
  setItems: (state, items) => {
    state.allItems = items;
  },

  addItems: (state, items) => {
    for (let item of items) {
      state.allItems.push(item);
    }
  },

  setNumbersItems: (state, numbers) => {
    state.numbersItems = numbers;
  },

  setNextPage: (state, number) => {
    state.nextPage = number;
  },

  setLastPage: (state, value) => {
    state.lastPage = value;
  },

  setCurrentPageNews(state, value) {
    state.currentPageNews = value;
  },

  setSelectedNews(state, array) {
    state.selectedNews = array;
  },

  setSelectOneNews(state, id) {
    if (state.selectedNews.includes(id)) {
      state.selectedNews.splice(state.selectedNews.indexOf(id), 1);
    } else {
      state.selectedNews.push(id);
    }
  },

  setCityForWebsite(state, data) {
    state.cityForWebsite = data;
  },
};

export const actions = {
  async retrieveItems({ state, commit, dispatch }, payload = 1) {
    commit("setSelectedNews", []);
    dispatch("retrieveCityForWebsite");

    try {
      let {
        news: items,
        total: numbers,
        page: currentPage,
        number_pages,
      } = await this.$axios.$get(
        `/api/news/${state.currentPageNews}/get?page=${payload}`
      );

      if (currentPage < number_pages) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setItems", items);
      commit("setNextPage", ++currentPage);
      commit("setNumbersItems", numbers);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },

  async retrieveNextItems({ state, commit }) {
    try {
      let {
        news: items,
        total: numbers,
        page: currentPage,
        number_pages,
      } = await this.$axios.$get(
        `/api/news/${state.currentPageNews}/get?page=${state.nextPage}`
      );

      if (currentPage < number_pages) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("addItems", items);
      commit("setNextPage", ++currentPage);
      commit("setNumbersItems", numbers);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },

  async retrieveCityForWebsite({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/get_news_city");
      console.log(data);
      commit("setCityForWebsite", data);
    } catch (error) {
      commit("setCityForWebsite", {});
      console.log(error);
    }
  },
};

export const state = () => ({
  reviewsNew: [],

  reviewsActive: [],

  reviewsBlocked: [],
});

export const getters = {
  getReviewsNew: (state) => state.reviewsNew,

  getReviewsActive: (state) => state.reviewsActive,

  getReviewsBlocked: (state) => state.reviewsBlocked,
};

export const mutations = {
  setReviewsNew(state, reviews) {
    state.reviewsNew = reviews;
  },

  setReviewsActive(state, reviews) {
    state.reviewsActive = reviews;
  },

  setReviewsBlocked(state, reviews) {
    state.reviewsBlocked = reviews;
  },
};

export const actions = {
  async retrieveReviewsNew({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/reviews/manager/get");

      commit("setReviewsNew", data.reviews);

      console.log(data);
    } catch (error) {
      commit("setReviews", []);
      console.log(error);
    }
  },

  async retrieveReviewsActive({ commit }) {
    try {
      const { data } = await this.$axios.get(
        "/api/reviews/manager/get/approved"
      );

      commit("setReviewsActive", data.reviews);
      console.log(data);
    } catch (error) {
      commit("setReviewsActive", []);
      console.log(error);
    }
  },

  async retrieveReviewsBlocked({ commit }) {
    try {
      const { data } = await this.$axios.get(
        "/api/reviews/manager/get/blocked"
      );

      commit("setReviewsBlocked", data.reviews);
      console.log(data);
    } catch (error) {
      commit("setReviewsBlocked", []);
      console.log(error);
    }
  },
};

import { render, staticRenderFns } from "./input-control.vue?vue&type=template&id=58db76be"
import script from "./input-control.vue?vue&type=script&lang=js"
export * from "./input-control.vue?vue&type=script&lang=js"
import style0 from "./input-control.vue?vue&type=style&index=0&id=58db76be&prod&lang=scss"
import style1 from "./input-control.vue?vue&type=style&index=1&id=58db76be&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VTextField: require('/app/node_modules/vuetify/lib/components/VTextField/VTextField.js').default,FormControl: require('/app/components/ui/form-control/form-control.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})

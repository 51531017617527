import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=b8095f48&scoped=true"
import script from "./checkbox.vue?vue&type=script&lang=js"
export * from "./checkbox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8095f48",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VCheckbox: require('/app/node_modules/vuetify/lib/components/VCheckbox/VCheckbox.js').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})

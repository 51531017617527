import { render, staticRenderFns } from "./HeaderNav.vue?vue&type=template&id=7f1f052b"
import script from "./HeaderNav.vue?vue&type=script&lang=js"
export * from "./HeaderNav.vue?vue&type=script&lang=js"
import style0 from "./HeaderNav.vue?vue&type=style&index=0&id=7f1f052b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default,VListItem: require('/app/node_modules/vuetify/lib/components/VList/VListItem.js').default,VList: require('/app/node_modules/vuetify/lib/components/VList/VList.js').default,VMenu: require('/app/node_modules/vuetify/lib/components/VMenu/VMenu.js').default,IconDots: require('/app/components/icons/IconDots.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VListItem,VListItemTitle,VMenu})


import HeaderV2 from "~/components/v2/common/header/header-v2.vue";

export default {
  components: {
    HeaderV2,
  },

  middleware: ["catalog-routes", "isActive"],
};

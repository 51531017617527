import { render, staticRenderFns } from "./catalog-v2.vue?vue&type=template&id=576e3d48"
import script from "./catalog-v2.vue?vue&type=script&lang=js"
export * from "./catalog-v2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/app/components/TheHeader.vue').default,VApp: require('/app/node_modules/vuetify/lib/components/VApp/VApp.js').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})

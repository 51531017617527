export const state = () => ({
  photoGallery: [],
  countPhoto: 0,
  filters: {
    page: "1",
    gender: null,
    district: null,
    search: null,
  },
  emirate_photo_session_id: null,
  lastPage: false,
});

export const getters = {
  getGallery: (state) => state.photoGallery,

  getLastPage: (state) => state.lastPage,

  getCountPhotos: (state) => state.countPhoto,

  getSearch: (state) => state.filters.search,
};

export const mutations = {
  setPhotoGallery: (state, payload) => {
    state.photoGallery = payload;
  },

  setLastPage: (state, value) => {
    state.lastPage = value;
  },

  setNextPage: (state, number) => {
    state.filters.page = `${number + 1}`;
  },

  supplementPhoto: (state, payload) => {
    for (let photo of payload) {
      state.photoGallery.push(photo);
    }
  },

  setFilters: (state, filters) => {
    for (let key in filters) {
      state.filters[key] = filters[key];
    }
  },

  setFilter: (state, payload) => {
    state.filters[payload.key] = payload.value;
  },

  setCountPhoto: (state, count) => {
    state.countPhoto = count;
  },

  resetFilters: (state) => {
    state.filters = {
      page: "1",
      gender: null,
      district: null,
      search: null,
    };
  },

  setFavoriteById: (state, payload) => {
    const index = state.photoGallery.findIndex(
      (photo) => photo.id === payload.id
    );
    if (index === -1) return;
    state.photoGallery[index].in_favorites = payload.isFavorite;
  },

  setEmiratePhotoSessionId: (state, id) => {
    state.emirate_photo_session_id = id;
  },
};

export const actions = {
  async getPhoto({ commit, state, rootGetters }, payload) {
    const city = rootGetters["global/getCurrentCity"];
    if (payload && payload.page) {
      commit("setFilter", { key: "page", value: payload.page });
    }
    try {
      const { data, current_page, last_page, total, emirate_photo_session_id } =
        await this.$axios.$get("/api/photos", {
          params: {
            ...state.filters,
            city: city.id,
            emirate_photo_session_id: state.emirate_photo_session_id,
          },
        });

      commit("setCountPhoto", total);
      commit("setEmiratePhotoSessionId", emirate_photo_session_id);

      if (current_page < last_page) {
        commit("setNextPage", current_page);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }
      console.log(data);
      commit("setPhotoGallery", data);
    } catch (error) {
      console.log(error);
      commit("setPhotoGallery", []);
    }
  },

  async supplementPhoto({ state, commit, rootGetters }) {
    try {
      const city = rootGetters["global/getCurrentCity"];
      const { data, current_page, total, last_page, emirate_photo_session_id } =
        await this.$axios.$get("/api/photos", {
          params: {
            ...state.filters,
            city: city.id,
            emirate_photo_session_id: state.emirate_photo_session_id,
          },
        });

      commit("setCountPhoto", total);
      commit("setEmiratePhotoSessionId", emirate_photo_session_id);

      if (current_page < last_page) {
        commit("setNextPage", current_page);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("supplementPhoto", data);
    } catch (error) {
      console.log(error);
      commit("supplementPhoto", []);
    }
  },
};

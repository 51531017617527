export const state = () => ({
  news: [],
  numberNews: 0,
  nextPage: 1,
  lastPage: false,

  date_from: "",
  date_to: "",

  searchText: "",
});

export const getters = {
  getNews: (state) => state.news,

  getNumberNews: (state) => state.numberNews,

  getLastPage: (state) => state.lastPage,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setNews(state, news) {
    state.news = news;
  },

  setNumberNews(state, value) {
    state.numberNews = value;
  },

  setNextPage(state, number) {
    state.nextPage = number;
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setUploadNews(state, news) {
    state.news = [...state.news, ...news];
  },

  setDates(state, dates) {
    if (!dates.length) {
      state.date_from = "";
      state.date_to = "";
    } else if (dates.length === 1) {
      state.date_from = dates[0];
    } else {
      state.date_from = dates[0];
      state.dates_to = dates[1];
    }
  },

  setSearchText(state, text) {
    state.searchText = text;
  },

  setAdvertiserNewsViewed(state, payload) {
    for (let i = 0; i < state.news.length; i++) {
      if (state.news[i].id === payload.id) {
        state.news[i].viewed = payload.viewed;
        break;
      }
    }
  },
};

export const actions = {
  async retrieveNews({ state, commit }) {
    commit("setNextPage", 1);

    try {
      const { data } = await this.$axios.get(
        `/api/news/manager/get?page=${state.nextPage}&date_from=${state.date_from}&date_to=${state.date_to}&search=${state.searchText}`
      );
      if (data.page < data.number_pages) {
        commit("setNextPage", state.nextPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      console.log(data);
      commit("setNumberNews", data.total);
      commit("setNews", data.news);
    } catch (error) {
      commit("setNews", []);
      console.log(error);
    }
  },

  async uploadNews({ state, commit }) {
    try {
      const { data } = await this.$axios.get(
        `/api/news/manager/get?page=${state.nextPage}`
      );

      if (data.page < data.number_pages) {
        commit("setNextPage", state.nextPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setNumberNews", data.total);
      commit("setUploadNews", data.news);
    } catch (error) {
      commit("setUploadNews", []);
      console.log(error);
    }
  },

  async retrieveNewsForAdvertiser({ state, commit }, reset = true) {
    if (reset) {
      commit("setNextPage", 1);
    }
    try {
      const { data } = await this.$axios.get(
        `/api/news/advertiser/get?page=${state.nextPage}&date_from=${state.date_from}&date_to=${state.date_to}&search=${state.searchText}`
      );

      if (data.page < data.number_pages) {
        commit("setNextPage", state.nextPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setNumberNews", data.total);
      const news = reset ? data.news : [...state.news, ...data.news];
      commit("setNews", news);
    } catch (error) {
      commit("setNews", []);
      console.log(error);
    }
  },

  async readAdvertiserNews(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "news",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },
};

export const state = () => ({
  allItems: [],
  nextPage: 1,
  lastPage: false,
  numbersItems: 0,
  typeCodes: {},

  searchText: "",
});

export const getters = {
  getItems: (state) => state.allItems,

  getLastPage: (state) => state.lastPage,

  getNumbersItems: (state) => state.numbersItems,

  getTypeCodes: (state) => state.typeCodes,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setItems: (state, items) => {
    state.allItems = items;
  },

  addItems: (state, items) => {
    for (let item of items) {
      state.allItems.push(item);
    }
  },

  setNumbersItems: (state, numbers) => {
    state.numbersItems = numbers;
  },

  setNextPage: (state, number) => {
    state.nextPage = number;
  },

  setLastPage: (state, value) => {
    state.lastPage = value;
  },

  setTypeCodes: (state, codes) => {
    state.typeCodes = codes;
  },

  setSearchText(state, value) {
    state.searchText = value;
  },
};

export const actions = {
  async retrieveItems({ state, commit }, payload = 1) {
    try {
      let {
        blacklist: items,
        page: currentPage,
        total: numbers,
        number_pages,
      } = await this.$axios.$get(
        `/api/blacklist/manager/get?page=${payload}&search=${state.searchText}`
      );
      const { data: typeCodes } = await this.$axios.get("/api/blacklist/types");

      commit("setTypeCodes", typeCodes);

      if (currentPage < number_pages) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setItems", items);
      commit("setNextPage", ++currentPage);
      commit("setNumbersItems", numbers);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },

  async retrieveNextItems({ state, commit }) {
    try {
      let {
        blacklist: items,
        page: currentPage,
        total: numbers,
        number_pages,
      } = await this.$axios.$get(
        `/api/blacklist/manager/get?page=${state.nextPage}&search=${state.searchText}`
      );

      if (currentPage < number_pages) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("addItems", items);
      commit("setNextPage", ++currentPage);
      commit("setNumbersItems", numbers);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },
};

import { render, staticRenderFns } from "./checkbox-control.vue?vue&type=template&id=010ede5b"
import script from "./checkbox-control.vue?vue&type=script&lang=js"
export * from "./checkbox-control.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Checkbox: require('/app/components/ui/checkbox/checkbox.vue').default,FormControl: require('/app/components/ui/form-control/form-control.vue').default})


import { advancedTabData as data } from "./data";
import { FormControl } from "~/components/ui/form-control";
import { InputControl } from "~/components/ui/input";
import { objectToArrayOfObjects } from "~/common/helpers";
import { mapGetters } from "vuex";
import { setSelectedOptions } from "~/common/helpers";

export default {
  name: "AdvancedTab",
  components: { FormControl, InputControl },
  props: ["helpers", "value"],

  data() {
    return { data };
  },

  computed: {
    ...mapGetters({
      filters: "profiles/getFilters",
    }),
    state: {
      get() {
        return this.value;
      },
      set(state) {
        this.$emit("input", { ...this.value, ...state });
      },
    },
    selectList() {
      return [
        {
          label: "Service",
          key: "service",
          items: this.servicesSelectOptions,
        },
        {
          label: "Age",
          key: "age",
          items: objectToArrayOfObjects(this.helpers.age),
        },
        {
          label: "Languages",
          key: "languages",
          items: objectToArrayOfObjects(this.helpers.languages),
        },
        {
          label: "Ethnicity",
          key: "ethnicity",
          items: this.ethnicitySelectOptions,
        },
        {
          label: "Nationalities",
          key: "nationalities",
          items: this.nationalitiesSelectOptions,
        },
        {
          label: "Orientation",
          key: "orientation",
          items: objectToArrayOfObjects(this.helpers.orientation),
        },
        {
          label: "Height",
          key: "height",
          items: objectToArrayOfObjects(this.helpers.height),
        },
        {
          label: "Weight",
          key: "weight",
          items: objectToArrayOfObjects(this.helpers.weight),
        },
        {
          label: "Bust size",
          key: "cupsize",
          items: objectToArrayOfObjects(this.helpers.breast_size),
        },
      ];
    },
    servicesSelectOptions() {
      const service = this.filters.service;
      const services = objectToArrayOfObjects(this.helpers.services);
      return setSelectedOptions(services, service);
    },
    nationalitiesSelectOptions() {
      const nationalities = objectToArrayOfObjects(this.helpers.nationality);
      const selectedNationalities = this.filters.nationalities;
      return setSelectedOptions(nationalities, selectedNationalities);
    },
    ethnicitySelectOptions() {
      const ethnicities = objectToArrayOfObjects(this.helpers.ethnicity);
      const selectedEthnicity = this.filters.ethnicity;
      return setSelectedOptions(ethnicities, "" + selectedEthnicity);
    },
  },
};

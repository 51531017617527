
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showAccountSettings: false,
    };
  },

  computed: {
    ...mapGetters({
      notifications: "notifications/getSidebarNotifications",
    }),

    messagesNotifications() {
      return this.notifications.messages;
    },

    bellNotifications() {
      let number = 0;

      for (let key in this.notifications) {
        if (key === "messages") continue;
        if (key === "pinboard") continue;
        if (key == "tariff") continue;
        else number += this.notifications[key];
      }

      return number;
    },

    favoriteUrl() {
      if (!this.$auth.loggedIn) {
        return "/auth/signin";
      }
      return this.$auth.user.user_role === 3
        ? "/dashboard/member/my-favourites"
        : this.$auth.user.user_role === 1
        ? "/dashboard/manager/favourites"
        : "/dashboard/my-favourites";
    },

    pmLink() {
      if (!this.$auth.loggedIn) {
        return "/auth/signin";
      }
      return this.$auth.user.user_role === 3
        ? "/dashboard/member/private-messages"
        : this.$auth.user.user_role === 1
        ? "/dashboard/manager/messages"
        : "/dashboard/private-messages";
    },
  },

  methods: {
    logOut() {
      this.$auth.logout();
    },

    goToDashboard() {
      this.$router.push("/dashboard");
      this.closeSettings();
    },

    toggleSettings() {
      this.showAccountSettings = !this.showAccountSettings;
    },

    closeSettings() {
      this.showAccountSettings = false;
    },
  },
};

import locale5f43b30e from '../../i18n/lazy_load_locales/en-GB.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en-GB"},
  vueI18nLoader: false,
  locales: [{"code":"en-GB","file":"en-GB.js"}],
  defaultLocale: "en-GB",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "i18n/lazy_load_locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_lang","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en-GB","file":"en-GB.js"}],
  localeCodes: ["en-GB"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "G",
  6: "B",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "i",
  17: "1",
  18: "8",
  19: "n",
  20: "/",
  21: "l",
  22: "a",
  23: "z",
  24: "y",
  25: "_",
  26: "l",
  27: "o",
  28: "a",
  29: "d",
  30: "_",
  31: "l",
  32: "o",
  33: "c",
  34: "a",
  35: "l",
  36: "e",
  37: "s",
  38: "/",
  39: "e",
  40: "n",
  41: "-",
  42: "G",
  43: "B",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: "}",
}

export const localeMessages = {
  'en-GB.js': () => Promise.resolve(locale5f43b30e),
}

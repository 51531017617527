import Vue from "vue";
import VueLazyload from "vue-lazyload";

export default async () => {
  Vue.use(VueLazyload, {
    preLoad: 0,
    // error: "https://via.placeholder.com/300",
    loading: require("~~/assets/img/Ripple-1s-800px.gif"),
    attempt: 1,
    lazyComponent: true,
    observer: true,
  });
};

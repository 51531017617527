export const state = () => ({
  notificationsList: [],
  currentPage: 1,
  numberItems: 0,
  lastPage: false,
  firstDateItem: "",

  searchText: "",
  date_from: "",
  date_to: "",
});

export const getters = {
  getNotifications: (state) => state.notificationsList,

  getLastPage: (state) => state.lastPage,

  getNumberItems: (state) => state.numberItems,

  getSearchText: (state) => state.searchText,

  getFirstDateItem: (state) => state.firstDateItem,
};

export const mutations = {
  setNextPage(state, value) {
    state.currentPage = value;
  },

  setNotifications(state, data) {
    state.notificationsList = data;
  },

  setNumberItems(state, value) {
    state.numberItems = value;
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  uploadNotifications(state, data) {
    state.notificationsList = [...state.notificationsList, ...data];
  },

  setSearchText(state, text) {
    state.searchText = text;
  },

  setDateFilter(state, dates) {
    if (!dates.length) {
      state.date_from = "";
      state.date_to = "";
    } else if (dates.length === 1) {
      state.date_from = dates[0];
      state.date_to = "";
    } else {
      state.date_from = dates[0];
      state.date_to = dates[1];
    }
  },

  setFirstDateItem(state, date) {
    state.firstDateItem = date;
  },
};

export const actions = {
  async retrieveNotifications({ state, commit }) {
    try {
      commit("setNextPage", 1);

      const { data } = await this.$axios.get(
        `/api/notifications?page=${state.currentPage}&search=${state.searchText}&date_from=${state.date_from}&date_to=${state.date_to}`
      );
      console.log(data);
      if (data.page < data.number_pages) {
        commit("setNextPage", state.currentPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setFirstDateItem", data.firstDataItem);
      commit("setNumberItems", data.total);
      commit("setNotifications", data.notifications);
    } catch (error) {
      commit("setNotifications", []);
      console.log(error);
    }
  },

  async uploadNotifications({ state, commit }) {
    try {
      const { data } = await this.$axios.get(
        `/api/notifications?page=${state.currentPage}&search=${state.searchText}&date_from=${state.date_from}&date_to=${state.date_to}`
      );

      if (data.page < data.number_pages) {
        commit("setNextPage", state.currentPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setNumberItems", data.total);
      commit("uploadNotifications", data.notifications);
    } catch (error) {
      commit("uploadNotifications", []);
      console.log(error);
    }
  },
};

export default function ({ store, redirect, route }) {
  if (
    route.path === "/dashboard/support" ||
    route.path === "/dashboard/member/support"
  ) {
    return;
  }
  // if user is not active, redirect to support page
  if (
    store?.$auth?.$state?.user &&
    store?.$auth?.$state?.user?.active === "false"
  ) {
    // support path for advertiser
    if (store?.$auth?.$state?.user?.user_role === 2) {
      return redirect("/dashboard/support");
    }
    // support path for member
    if (store?.$auth?.$state?.user?.user_role === 3) {
      return redirect("/dashboard/member/support");
    }
  }
}

export const state = () => ({
  discreetMode: null,
  contactsData: [],
});

export const getters = {
  getDiscreetStatus: (state) => state.discreetMode,

  getContacts: (state) => state.contactsData,
};

export const mutations = {
  setDiscreet(state) {
    state.discreetMode = !state.discreetMode;
  },

  setContacts(state, data) {
    state.contactsData = data;
  },

  initializeFromLocalStorage(state) {
    if (localStorage && localStorage.getItem("discreetMode")) {
      state.discreetMode = localStorage.getItem("discreetMode") === "true";
    }
  },
};

export const actions = {
  async retrieveContacts({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/get_contacts_messengers");

      commit("setContacts", data);
      console.log(data);
    } catch (error) {
      commit("setContacts", []);
      console.log(error);
    }
  },
};

export function bindDiscreetModeWithLocalstorage(store) {
  if (!process.browser) return;
  store.commit("initializeFromLocalStorage");
  store.subscribe((mutation, state) => {
    if (mutation.type === "setDiscreet") {
      localStorage.setItem("discreetMode", state.discreetMode);
    }
  });
}


export default {
  head() {
    return {
      meta: [
        {
          name: "robots",
          content: "noindex",
        },
      ],
    };
  },
};

// import Vue from 'vue'
// import VueAwesomeSwiper from 'vue-awesome-swiper'

// import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/core'

// // Install modules
// Swiper.use([Navigation, Pagination, Scrollbar]);
// //https://github.com/surmon-china/vue-awesome-swiper

// SwiperClass.use([Pagination, Mousewheel, Autoplay])

// // import style (>= Swiper 6.x)

// // import style (<= Swiper 5.x)
// // import 'swiper/css/swiper.css'

// Vue.use(getAwesomeSwiper(SwiperClass))
// // Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

import Vue from "vue";
import "swiper/swiper-bundle.css";
// Swiper 5.x
// import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/js/swiper.esm'
// Swiper 6.x
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from "swiper/core";

import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

// Swiper modules
SwiperClass.use([Pagination, Navigation, Mousewheel, Autoplay]);

// -------------------------------------------------

// Global use
Vue.use(getAwesomeSwiper(SwiperClass));

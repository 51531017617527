
export default {
  data() {
    return {
      images: [],
      titles: [],
      titleUrls: [],
      sourceButtons: [],
      currentImageIndex: 0,
      closed: true,
      uiTimeout: null,
      handlers: {},
      thumbnails: false,
    };
  },
  watch: {
    closed(newVal) {
      if (newVal && this.handlers.closed) {
        this.handlers.closed();
      }
      if (!newVal && this.handlers.opened) {
        this.handlers.opened();
      }
    },
  },
  created() {
    if (!window) return;
    window.addEventListener("keyup", (e) => {
      // esc key and 'q' for quit
      if (e.key === "Escape" || e.key === "q") this.close();
      // arrow right and 'l' key (vim-like binding)
      if (e.key === "ArrowRight" || e.key === "l") this.next();
      // arrow left and 'h' key (vim-like binding)
      if (e.key === "ArrowLeft" || e.key === "h") this.prev();
    });
    window.addEventListener("scroll", () => {
      this.close();
    });
  },
  methods: {
    // Not using currentImageIndex watcher because it will
    // fire on all cases when opened not first image and
    // index should be changed in order to show clicked image
    // in the image set.
    fireChangeEvent() {
      if (this.handlers.changed) {
        this.handlers.changed(this.currentImageIndex);
      }
    },
    close() {
      if (!this.closed) {
        document.querySelector("body").classList.remove("body-fs-v-img");
        this.images = [];
        this.currentImageIndex = 0;
        this.closed = true;
      }
    },
    next() {
      if (!this.closed && this.images.length > 1) {
        // if next index not exists in array of images, set index to first element
        if (this.currentImageIndex + 1 < this.images.length) {
          this.currentImageIndex++;
        } else {
          this.currentImageIndex = 0;
        }
        this.fireChangeEvent();
      }
    },
    select(selectedImage) {
      this.currentImageIndex = selectedImage;
    },
    prev() {
      if (!this.closed && this.images.length > 1) {
        // if prev index not exists in array of images, set index to last element
        if (this.currentImageIndex > 0) {
          this.currentImageIndex--;
        } else {
          this.currentImageIndex = this.images.length - 1;
        }
        this.fireChangeEvent();
      }
    },
  },
};

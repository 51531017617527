export const state = () => ({
  supportList: [],
  supportSubjects: {},
  date_from: "",
  date_to: "",

  searchText: "",

  lastPage: false,
  numberLists: 0,
  currentPage: 1,
  perPage: 20,
});

export const getters = {
  getSupportList: (state) => state.supportList,

  getSupportSubjects: (state) => state.supportSubjects,

  getSearchText: (state) => state.searchText,

  getLastPage: (state) => state.lastPage,

  getNumberLists: (state) => state.numberLists,

  getSupportById: (state) => (id) => {
    return state.supportList.find((support) => support.id === id);
  },
};

export const mutations = {
  setSupportList(state, list) {
    state.supportList = list;
  },

  setUploadSupportLists(state, list) {
    state.supportList = [...state.supportList, ...list];
  },

  setSupportSubjects(state, list) {
    state.supportSubjects = list;
  },

  setDateFilter(state, value) {
    state.date_to = "";
    state.date_from = "";

    if (value.length === 1 && value.length !== 0) {
      state.date_from = value[0];
    } else if (value.length === 2) {
      state.date_from = value[0];
      state.date_to = value[1];
    }
  },

  setSearchFilter(state, value) {
    state.searchText = value;
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setNumberLists(state, number) {
    state.numberLists = number;
  },

  setCurrentPage(state, value) {
    state.currentPage = value;
  },

  setNewMessages(state, payload) {
    for (let i = 0; i < state.supportList.length; i++) {
      if (state.supportList[i].id === payload.id) {
        state.supportList[i].new_messages = payload.new_messages;
        return;
      }
    }
  },
};

export const actions = {
  async retrieveSupport({ state, commit, dispatch }) {
    commit("setCurrentPage", 1);

    try {
      const { data } = await this.$axios.get("/api/support", {
        params: {
          active: "all",
          date_from: state.date_from,
          date_to: state.date_to,
          search: state.searchText,
          page: state.currentPage,
          per_page: state.perPage,
        },
      });

      await dispatch("retrieveSupportSubject");

      commit("setSupportList", data.data);
      commit("setNumberLists", data.total);

      if (data.current_page < data.last_page) {
        commit("setLastPage", false);
        commit("setCurrentPage", state.currentPage + 1);
      } else {
        commit("setLastPage", true);
      }
    } catch (error) {
      commit("setSupportList", []);
      console.log(error);
    }
  },

  async uploadSupportLists({ state, commit }) {
    try {
      const { data } = await this.$axios.get("/api/support", {
        params: {
          active: "all",
          date_from: state.date_from,
          date_to: state.date_to,
          search: state.searchText,
          page: state.currentPage,
          per_page: state.perPage,
        },
      });

      commit("setNumberLists", data.total);
      commit("setUploadSupportLists", data.data);

      if (data.current_page < data.last_page) {
        commit("setLastPage", false);
        commit("setCurrentPage", state.currentPage + 1);
      } else {
        commit("setLastPage", true);
      }
    } catch (error) {
      commit("setUploadSupportLists", []);
      console.log(error);
    }
  },

  async retrieveSupportSubject({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/support/get_subjects");

      commit("setSupportSubjects", data);
    } catch (error) {
      commit("setSupportSubjects", {});
      console.log(error);
    }
  },

  async readSupport(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "support",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },
};


import { mapGetters, mapMutations } from "vuex";
import {
  AdvancedTab,
  AvailabilityTab,
  LocationTab,
  TextSearchTab,
} from "./tabs";

const initState = {
  serviceFor: {},
  location: {},
};

export default {
  name: "SearchModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    tabToOpen: {
      type: String,
      default: "advanced",
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      state: initState,
      tabList: [
        {
          name: "advanced",
          component: AdvancedTab,
          text: this.$t("advanced-search"),
        },
        {
          name: "location",
          component: LocationTab,
          text: this.$t("by-location"),
        },
        {
          name: "availability",
          component: AvailabilityTab,
          text: this.$t("by-availability"),
        },
        {
          name: "text_search",
          component: TextSearchTab,
          text: this.$t("text-search"),
        },
      ],
      selectedTab: this.tabToOpen,
    };
  },
  computed: {
    ...mapGetters({ getFiltersList: "profiles/getFiltersList" }),
    currentTab() {
      return this.tabList.find((tab) => tab.name === this.selectedTab);
    },
  },
  methods: {
    ...mapMutations({ setFilters: "profiles/setFilters" }),

    onSelectTab(tabName) {
      this.selectedTab = tabName;
    },

    onSubmit() {
      const getString = (obj) =>
        Object.keys(obj)
          .filter((key) => obj[key])
          .join(",");

      const params = {
        ...this.state,
        location: getString(this.state.location),
        serviceFor: getString(this.state.serviceFor),
      };

      this.setFilters(params);
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
      this.$emit("onClose");
    },

    handleClose() {
      this.$emit("onClose");
      this.state = initState;
    },

    async onOpen() {
      this.selectedTab = this.tabToOpen;
      if (Object.keys(this.getFiltersList).length) return;
      try {
        this.$axios.get("/api/get_full_data");
      } catch (err) {
        console.error(err);
      }
    },
  },
};

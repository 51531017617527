
export default {
  name: "CLoader",

  props: {
    size: {
      type: Number,
      default: 30,
    },
    trackWidth: {
      type: Number,
      default: 3,
    },
    theme: {
      type: String,
      default: "default", // default | accent
    },
  },

  computed: {
    cssVars() {
      return {
        "--size": `${this.size}px`,
        "--track-width": `${this.trackWidth}px`,
      };
    },
  },
};


import { mapGetters } from "vuex";
import { camelCaseToDashes } from "~/common/helpers";
import { mdiCloseCircle } from "@mdi/js";

export default {
  props: {
    urlPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mdiCloseCircle,
    };
  },

  computed: {
    ...mapGetters({
      statusNav: "menus/getStatusNav",
      currentLocationUrl: "global/getCurrentLocationUrl",
      decodedRouteParams: "profiles/getDecodedRouteParams",
      filters: "profiles/getFilters",
    }),
    routeName() {
      return this.$route.name;
    },
    gender() {
      if (!this.isCatalog) {
        return "female";
      }
      const genders = ["male", "female", "transgender"];

      return genders.find((sub) => {
        const regex = new RegExp(`/${sub}(?=/|$)`);
        return regex.test(this.$route.path);
      });
    },
    isCatalog() {
      return this.routeName.includes("catalog");
    },
    isMain() {
      return this.routeName == "index";
    },
  },
  methods: {
    categoryToUrl(category) {
      let path = "";
      if (this.isMain) {
        path = "dubai/female";
      } else {
        path += this.currentLocationUrl;
        path += this.gender ? "/" + this.gender : "";
      }
      path += "/" + camelCaseToDashes(category);
      return path;
    },

    cancelStatus() {
      const pathWithoutStatus = this.$route.path
        .split("/")
        .slice(0, -1)
        .join("/");
      this.$router.push({ path: pathWithoutStatus });
    },

    fetchProfilesByStatus(filterId, value) {
      const newFilters = {};
      this.statusNav.forEach((item) => {
        newFilters[item.id] = item.id === filterId ? value : "";
      });

      this.$store.commit("profiles/setFilters", newFilters);
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
    },

    isSelectedBtn(itemId) {
      return (
        Object.keys(this.filters || {}).includes(itemId) &&
        (this.filters || {})[itemId] != "0"
      );
    },
  },
};

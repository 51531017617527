
import cBtn from "./ui/button/c-btn.vue";
export default {
  components: { cBtn },
  props: {
    seoSettings: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isOpenFull: false,
    };
  },

  computed: {
    cssVars() {
      return {
        "--short-height": this.seoSettings.shortHeight
          ? `${this.seoSettings.shortHeight}px`
          : null,
        "--short-line-count": this.seoSettings.shortLineCount,
      };
    },

    seoWrapperClasses() {
      return {
        "seo-wrapper": true,
        "seo-wrapper--height-short":
          !this.isOpenFull &&
          this.seoSettings.isShowMoreEnabled &&
          this.seoSettings.shortHeight,
        "seo-wrapper--line-short":
          !this.isOpenFull &&
          this.seoSettings.isShowMoreEnabled &&
          this.seoSettings.shortLineCount,
        "seo-wrapper--full-height": this.isOpenFull,
      };
    },

    showMoreBtnText() {
      return this.isOpenFull ? this.$t("show-less") : this.$t("show-more");
    },
  },

  methods: {
    toggleShowMore() {
      this.isOpenFull = !this.isOpenFull;
    },
  },
};

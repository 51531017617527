export const state = () => ({
  tariff_info: [],
  tariff_actions: {},
  tariff_id: null,
  transactions: [],
  balance: 0,

  date_from: "",
  date_to: "",
  per_page: 50,
  page: 1,
  total: 0,
});

export const getters = {
  getBilling: (state) => state.tariff_info,

  getTariffActions: (state) => state.tariff_actions,

  // TODO: remove
  getTariffId: (state) => state.tariff_id,

  getTransactions: (state) => state.transactions,

  getBalance: (state) => state.balance,

  getTotalTransactions: (state) => state.total,

  getPage: (state) => state.page,
};

export const mutations = {
  setBillings(state, billing) {
    state.tariff_info = billing;
  },

  setTariffActions(state, list) {
    state.tariff_actions = list;
  },

  setTariffId(state, id) {
    state.tariff_id = id;
  },

  setTransactions(state, data) {
    state.transactions = data;
  },

  setDates(state, dates) {
    if (!dates.length) {
      state.date_from = "";
      state.date_to = "";
    } else if (dates.length === 1) {
      state.date_from = dates[0];
      state.date_to = "";
    } else {
      state.date_from = dates[0];
      state.date_to = dates[1];
    }
  },

  setBalance(state, balance) {
    state.balance = balance;
  },

  setPage(state, page) {
    state.page = page;
  },

  setTotal(state, total) {
    state.total = total;
  },
};

export const actions = {
  async retrieveBilling({ state, commit }, page) {
    try {
      const { data } = await this.$axios.get("/api/billing", {
        params: {
          date_from: state.date_from,
          date_to: state.date_to,
          per_page: state.per_page,
          page: page || state.page,
        },
      });

      if (page) {
        commit("setTransactions", data.transactions);
      } else {
        commit("setTransactions", [
          ...state.transactions,
          ...data.transactions,
        ]);
      }
      commit("setTariffId", data.tariff_id);
      commit("setTariffActions", data.tariff_actions_list);
      commit("setBillings", data.tariff_info);
      commit("setBalance", data.balance);
      commit("setTotal", data.total);
      commit("setPage", data.page + 1);
    } catch (error) {
      commit("setBillings", []);
      console.log(error);
    }
  },
};

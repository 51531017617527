
import breadcrumbs from "~/components/global/breadcrumbs.vue";
import HeaderV2 from "~/components/v2/common/header/header-v2.vue";

export default {
  components: { breadcrumbs, HeaderV2 },

  computed: {
    isMobile() {
      return this.$store.state.global.mobile;
    },
  },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }

    // this.$root.$on("set-page-padding", () => {
    //   this.setPagePadding();
    // });
  },

  // methods: {
  //   setPagePadding() {
  //     if (!this.$refs.header) return;
  //     const headerHeight =
  //       this.$refs.header.$el.querySelector("header").clientHeight;
  //     const main = document.querySelector("main");
  //     main.style.paddingTop = `${headerHeight + 10}px`;
  //   },
  // },
};


export default {
  name: "CBtn",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: true,
    },

    theme: {
      type: String,
      default: "light", // light, accent, green, dark, transparent, text-only
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: "medium", // small, medium
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    loaderSize() {
      switch (this.size) {
        case "small":
          return 25;
        case "medium":
          return 30;
        default:
          return 30;
      }
    },
  },
};


export default {
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium", // small, medium, large
    },
    switchType: {
      type: String,
      default: "thick", // thin, thick
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentValue: this.value,
    };
  },

  computed: {
    isActive() {
      return this.currentValue === this.trueValue;
    },

    classes() {
      return {
        "c-switch": true,
        "c-switch--disabled": this.disabled,
        "c-switch--active": this.isActive,
        "c-switch--small": this.size === "small",
        "c-switch--medium": this.size === "medium",
        "c-switch--large": this.size === "large",
        "c-switch--thin": this.switchType === "thin",
        "c-switch--thick": this.switchType === "thick",
      };
    },
  },

  watch: {
    value(val) {
      this.currentValue = val;
    },
  },

  methods: {
    toggle() {
      if (this.disabled) return;
      if (!this.readOnly) {
        this.currentValue =
          this.currentValue === this.trueValue
            ? this.falseValue
            : this.trueValue;
      }
      this.$emit("update:value", this.currentValue);
      this.$emit("input", this.currentValue);
    },
  },
};

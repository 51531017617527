export const state = () => ({
  tariffs: [],
});

export const getters = {
  getTariffs: (state) => state.tariffs,
  getTariffById: (state) => (id) => {
    return state.tariffs.find((tariff) => tariff.id === id);
  },
};

export const mutations = {
  setTariffs(state, tariffs) {
    state.tariffs = tariffs;
  },
};

export const actions = {
  async retrieveTariffs({ commit }) {
    const { data } = await this.$axios.get("/api/get-tariffs-data");
    commit("setTariffs", data);
  },
};


import { FormControl } from "../form-control";

export default {
  name: "InputControl",
  components: { FormControl },
  props: ["value", "className", "label", "placeholder"],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
};

export const state = () => ({
  newsList: [],

  currentPage: 1,
  lastPage: false,
  amountNews: 0,

  searchText: "",
  date_from: "",
  date_to: "",
  district_id: null,
});

export const getters = {
  getNewsList: (state) => state.newsList,

  getLastPage: (state) => state.lastPage,

  getAmountNews: (state) => state.amountNews,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setNewsList(state, news) {
    state.newsList = news;
  },

  setUploadNews(state, news) {
    state.newsList = [...state.newsList, ...news];
  },

  setNextPage(state, value) {
    state.currentPage = value;
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setAmountNews(state, value) {
    state.amountNews = value;
  },

  setDatesSearch(state, dates) {
    if (!dates.length) {
      state.date_from = "";
      state.date_to = "";
    } else if (dates.length === 1) {
      state.date_from = dates[0];
      state.date_to = "";
    } else {
      state.date_from = dates[0];
      state.date_to = dates[1];
    }
  },

  setDistrictFilter(state, data) {
    state.district_id = data;
  },

  setSearchText(state, value) {
    state.searchText = value;
  },

  resetFilters(state) {
    state.searchText = "";
    state.date_from = "";
    state.date_to = "";
    state.district_id = null;
  },
};

export const actions = {
  async retirieveWebsiteNews({ state, commit, rootGetters }) {
    commit("setNextPage", 1);

    try {
      const city = rootGetters["global/getCurrentCity"];
      const { data } = await this.$axios.get("/api/news/website/get", {
        params: {
          page: state.currentPage,
          city_id: city.id,
          date_from: state.date_from,
          date_to: state.date_to,
          district_id: state.district_id ? `[${state.district_id}]` : null,
          search: state.searchText,
        },
      });

      console.log(data);
      if (data.page < data.number_pages) {
        commit("setNextPage", state.currentPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setAmountNews", data.total);
      commit("setNewsList", data.news);
    } catch (error) {
      commit("setNewsList", []);
      console.log(error);
    }
  },

  async uploadWebsiteNews({ state, commit, rootGetters }) {
    try {
      const city = rootGetters["global/getCurrentCity"];
      const { data } = await this.$axios.get("/api/news/website/get", {
        params: {
          page: state.currentPage,
          city_id: city.id,
          date_from: state.date_from,
          date_to: state.date_to,
          search: state.searchText,
          district_id: state.district_id ? `[${state.district_id}]` : null,
        },
      });

      if (data.page < data.number_pages) {
        commit("setNextPage", state.currentPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setAmountNews", data.total);
      commit("setUploadNews", data.news);
    } catch (error) {
      commit("setUploadNews", []);
      console.log(error);
    }
  },
};


export default {
  name: "LocationTab",
  props: ["helpers", "value"],
  computed: {
    state: {
      get() {
        return this.value;
      },
      set(state) {
        this.$emit("input", { ...this.value, ...state });
      },
    },
  },
};


export default {
  data() {
    return {
      isAdult: true,
    };
  },

  watch: {
    isAdult: {
      handler() {
        if (!process.browser) return;
        if (this.isAdult) {
          this.unlockScroll();
        } else {
          this.lockScroll();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.initializeIsAdultFromStorage();
  },

  methods: {
    leaveWebsite() {
      window.location.href = "https://www.google.com";
    },

    iAmOver18() {
      if (!process.browser) return;
      if (localStorage) {
        this.isAdult = true;
        localStorage.setItem("isAdult", true);
      }
    },

    initializeIsAdultFromStorage() {
      if (!process.browser) return;
      if (localStorage) {
        this.isAdult = localStorage.getItem("isAdult") === "true";
      }
    },

    lockScroll() {
      document.body.parentNode.style.overflowY = "hidden";
    },

    unlockScroll() {
      document.body.parentNode.style.overflowY = "auto";
    },
  },
};

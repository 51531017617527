export default function ({ store, redirect }) {
  if (
    store.$auth.$state.user.user_role == 2 ||
    store.$auth.$state.user.user_role == 1
  ) {
    // do nothing
  } else {
    return redirect("/dashboard");
  }
}

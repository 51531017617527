
import { mapMutations } from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["close", "search"],

  data() {
    return {
      form: {
        searchQuery: "",
      },
    };
  },

  computed: {
    isCanSearch() {
      return this.form.searchQuery.length >= 2;
    },

    searchBtnTooltip() {
      return this.isCanSearch ? "" : this.$t("enter-at-least-2-characters");
    },
  },

  methods: {
    ...mapMutations({ setFilters: "profiles/setFilters" }),

    close() {
      this.form.searchQuery = "";
      this.$emit("close");
    },

    search() {
      this.setFilters(this.form);
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
      this.close();
    },
  },
};

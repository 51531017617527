import menus from "~/static/menus.json";

export const state = () => ({
  headerToFooterNav: menus.headerToFooterNav,
  footerNav: menus.footerNav,
  statusNav: [
    {
      id: "new",
      name: "New",
      active: false,
      count: 0,
      url: "new",
    },
    {
      id: "awaitingCall",
      name: "Awaiting Call",
      active: false,
      count: 0,
      url: "awaiting-call",
    },
    {
      id: "qualityMark",
      name: "Quality Mark",
      active: false,
      count: 0,
      url: "quality-mark",
    },
    {
      id: "verified",
      name: "Verified",
      active: false,
      count: 0,
      url: "verified",
    },
    {
      id: "incall",
      name: "Incall",
      active: false,
      count: 0,
      url: "incall-services-escorts",
    },
    {
      id: "outcall",
      name: "Outcall",
      active: false,
      count: 0,
      url: "outcall-services-escorts",
    },
    {
      id: "onTour",
      name: "On Tour",
      active: false,
      count: 0,
      url: "on-tour",
    },
  ],
  profileNav: menus.profileNav,
});

export const getters = {
  getHeaderNav: (state, getters, rootState, rootGetters) => () => {
    const catalogUrl = rootGetters["global/getCurrentLocationUrl"];

    return [
      {
        id: 8,
        name: "Catalog",
        link: `/${catalogUrl}/female`,
        isShowMobile: false,
        subMenu: [
          { id: 96, name: "Female", link: `/${catalogUrl}/female` },
          { id: 97, name: "Shemale", link: `/${catalogUrl}/transgender` },
          { id: 98, name: "Male", link: `/${catalogUrl}/male` },
          { id: 99, name: "All", link: "/" + catalogUrl },
        ],
      },
      {
        id: 6,
        name: "Live feed",
        link: "/escorts-updates",
        isShowMobile: true,
      },
      {
        id: 1,
        name: "Reviews",
        link: "/reviews",
        isShowMobile: true,
      },
      {
        id: 3,
        name: "Video",
        link: "/escorts-videos",
        isShowMobile: true,
      },
      {
        id: 4,
        name: "Photo",
        link: "/escorts-photo-gallery",
        isShowMobile: true,
      },
      {
        id: 5,
        name: "News",
        link: "/escorts-news",
        isShowMobile: true,
      },
      {
        id: 9,
        name: "Categories",
        link: "/categories/female",
        isShowMobile: true,
      },
    ];
  },

  getHeaderToFooterNav: (state) => state.headerToFooterNav,

  getFooterNav: (state) => state.footerNav,

  getStatusNav: (state) => state.statusNav,

  getStatusNavFilterById: (state) => (id) => {
    return state.statusNav.find((el) => el.id == id);
  },
};

export const mutations = {
  changeActiveFilterById(state, id, active = true) {
    this.commit("menus/unActiveAll");
    state.statusNav.find((el) => (el.id == id ? (el.active = active) : ""));
  },

  unActiveAll(state) {
    state.statusNav.forEach((el) => {
      el.active = false;
    });
  },

  setNumberModelsFilter(state, data) {
    state.statusNav = state.statusNav.map((filter) => {
      if (Object.prototype.hasOwnProperty.call(data, filter.id))
        filter.count = data[filter.id];

      return filter;
    });
  },
};

export const actions = {
  async retrieveMainFilters({ commit }, route) {
    try {
      const { data } = await this.$axios.get("/api/models/filters_top", {
        params: {
          route,
        },
      });
      commit("setNumberModelsFilter", data);
    } catch (error) {
      commit("setNumberModelsFilter", {});
      console.log(error);
    }
  },
};

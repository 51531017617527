
import { mdiCheck, mdiCheckboxBlankCircle } from "@mdi/js";

export const props = ["label", "value", "input", "className"];

export default {
  name: "Checkbox",
  props,
  data() {
    return { mdiCheck, mdiCheckboxBlankCircle };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
};

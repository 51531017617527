export const state = () => ({
  openCatalogFilter: false,
  catalogMode: "grid",
  minCol: 2,
});

export const getters = {
  getCatalogMode: (state) => state.catalogMode,
  getMinCol: (state) => state.minCol,
};

export const mutations = {
  setCatalogFilter(state) {
    state.openCatalogFilter = !state.openCatalogFilter;
  },
  setCatalogMode(state, payload) {
    state.catalogMode = payload;
  },
  setMinCol(state, payload) {
    state.minCol = payload;
  },
};

export const actions = {};

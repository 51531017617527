
import { mdiCheck } from "@mdi/js";
import { mapGetters, mapMutations } from "vuex";
import { objectToArrayOfObjects } from "~/common/helpers";

const defaultForm = {
  withVideo: "0",
  withReviews: "0",
  qualityMark: "0",
  has_natural_photo: "0",
  big_ass: "0",
  verified: false,
  showOnlySingleProfiles: false,

  service_for: [],

  availability: [],
  service: [],
  age: [],
  languages: [],
  ethnicity: [],
  nationality: [],
  orientation: [],
  height: [],
  weight: [],
  cupsize: [],
  district: [],
};

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["close", "search"],

  data() {
    return {
      mdiCheck,

      form: { ...defaultForm },

      checkboxes: [
        {
          label: this.$t("with-video"),
          value: "withVideo",
          trueValue: "1",
          falseValue: "0",
        },
        {
          label: this.$t("with-reviews"),
          value: "withReviews",
          trueValue: "1",
          falseValue: "0",
        },
        {
          label: this.$t("has-natural-photo"),
          value: "has_natural_photo",
          trueValue: "1",
          falseValue: "0",
        },
        {
          label: this.$t("quality-mark"),
          value: "qualityMark",
          trueValue: "1",
          falseValue: "0",
        },
        {
          label: this.$t("show-only-single-profiles"),
          value: "showOnlySingleProfiles",
          trueValue: true,
          falseValue: false,
        },
        {
          label: this.$t("verified"),
          value: "verified",
          trueValue: "1",
          falseValue: "0",
        },
      ],

      service_for_checkboxes: [
        {
          label: this.$t("man"),
          value: "1",
        },
        {
          label: this.$t("woman"),
          value: "2",
        },
        {
          label: this.$t("couples"),
          value: "3",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getFiltersList: "profiles/getFiltersList",
      getFilters: "profiles/getFilters",
    }),

    filtersToUpdate() {
      const filters = { ...this.form };
      Object.keys(filters).forEach((key) => {
        if (
          filters[key] === "" ||
          filters[key] === null ||
          !filters[key]?.length
        ) {
          delete filters[key];
        }
      });
      if (filters.showOnlySingleProfiles) {
        delete filters.showOnlySingleProfiles;
        filters.duo = "0";
      }
      return filters;
    },

    selects() {
      return [
        {
          label: this.$t("area"),
          key: "district",
          items: this.districtOptions,
        },
        {
          label: this.$t("availability"),
          key: "availability",
          items: objectToArrayOfObjects({
            awaiting_call: this.$t("awaiting-call"),
            in_tour: this.$t("in-tour"),
            incall: this.$t("incall"),
            outcall: this.$t("outcall"),
            online: this.$t("online"),
          }),
        },
        {
          label: this.$t("service"),
          key: "service",
          items: objectToArrayOfObjects(this.getFiltersList.services),
        },
        {
          label: this.$t("age"),
          key: "age",
          items: objectToArrayOfObjects(this.getFiltersList.age),
        },
        {
          label: this.$t("languages"),
          key: "languages",
          items: objectToArrayOfObjects(this.getFiltersList.languages),
        },
        {
          label: this.$t("ethnicity"),
          key: "ethnicity",
          items: this.ethnicityItems,
        },
        {
          label: this.$t("nationalities"),
          key: "nationality",
          items: Object.keys(this.getFiltersList?.nationality_new || {}).map(
            (key) => {
              return {
                value: key,
                title: this.getFiltersList.nationality_new[key].title,
              };
            }
          ),
        },
        {
          label: this.$t("orientation"),
          key: "orientation",
          items: objectToArrayOfObjects(this.getFiltersList.orientation),
        },
        {
          label: this.$t("height"),
          key: "height",
          items: objectToArrayOfObjects(this.getFiltersList.height),
        },
        {
          label: this.$t("weight"),
          key: "weight",
          items: objectToArrayOfObjects(this.getFiltersList.weight),
        },
        {
          label: this.$t("bust-size"),
          key: "cupsize",
          items: objectToArrayOfObjects(this.getFiltersList.breast_size),
        },
      ];
    },

    ethnicityItems() {
      if (!this.getFiltersList?.ethnicity) return [];
      return this.getFiltersList.ethnicity.map((e) => {
        return {
          title: e.title,
          value: e.id,
        };
      });
    },

    districtOptions() {
      if (!this.getFiltersList || !this.getFiltersList.districts) {
        return [];
      }
      return this.getFiltersList.districts.map((district) => {
        const districtOption = {
          title: district.title,
          value: "" + district.id,
        };
        return districtOption;
      });
    },
  },

  watch: {
    getFilters: {
      handler(val) {
        const showOnlySingleProfiles = this.form.showOnlySingleProfiles;
        this.form = { ...defaultForm };
        this.form.showOnlySingleProfiles = showOnlySingleProfiles;
        Object.keys(val).forEach((key) => {
          if (this.form[key] !== undefined) {
            const value = val[key];
            console.log(key, value);
            if (
              this.selects.some((select) => select.key === key) ||
              key === "service_for"
            ) {
              this.form[key] = value.split(",");
            } else {
              this.form[key] = value;
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapMutations({ setFilters: "profiles/setFilters" }),

    close() {
      this.$emit("close");
    },

    closeAdvancedSearch() {
      this.$emit("close-advanced-search");
    },

    search() {
      this.setFilters(this.filtersToUpdate);
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
      this.close();
    },
  },
};

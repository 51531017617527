export const state = () => ({
  favouritesModels: [],
});

export const getters = {
  getFavouritesModels: (state) => state.favouritesModels,
};

export const mutations = {
  setFavouritesModels(state, models) {
    state.favouritesModels = models;
  },
};

export const actions = {
  async retrieveFavouritesModels({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/favorites");
      commit("setFavouritesModels", data);
    } catch (error) {
      commit("setFavouritesModels", []);
      console.log(error);
    }
  },
};

export const state = () => ({
  bannerSizes: {},

  allBanners: [],
});

export const getters = {
  getBannerSizes: (state) => state.bannerSizes,

  getAllBanners: (state) => state.allBanners,
};

export const mutations = {
  setBannerSizes(state, data) {
    state.bannerSizes = data;
  },

  setAllBanners(state, banners) {
    state.allBanners = banners;
  },
};

export const actions = {
  async retrieveBannerSizes({ commit, dispatch }) {
    try {
      const { data } = await this.$axios.get("/api/get_banners_sizes");

      await dispatch("retrieveAllBanners");

      commit("setBannerSizes", data);
    } catch (error) {
      commit("setBannerSizes", {});
      console.log(error);
    }
  },

  async retrieveAllBanners({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/banners/manager/get");

      commit("setAllBanners", data.banners);
    } catch (error) {
      commit("setAllBanners", []);
      console.log(error);
    }
  },
};

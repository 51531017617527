import Vue from "vue";
import { createSimpleFunctional } from "vuetify/lib/util/helpers"
import {ClickOutside} from "/app/node_modules/vuetify/lib/directives/click-outside/index.js"
import {Color} from "/app/node_modules/vuetify/lib/directives/color/index.js"
import {Intersect} from "/app/node_modules/vuetify/lib/directives/intersect/index.js"
import {Mutate} from "/app/node_modules/vuetify/lib/directives/mutate/index.js"
import {Resize} from "/app/node_modules/vuetify/lib/directives/resize/index.js"
import {Ripple} from "/app/node_modules/vuetify/lib/directives/ripple/index.js"
import {Scroll} from "/app/node_modules/vuetify/lib/directives/scroll/index.js"
import {Touch} from "/app/node_modules/vuetify/lib/directives/touch/index.js"

Vue.component('VCardActions', createSimpleFunctional('v-card__actions'))
Vue.component('VCardSubtitle', createSimpleFunctional('v-card__subtitle'))
Vue.component('VCardText', createSimpleFunctional('v-card__text'))
Vue.component('VCardTitle', createSimpleFunctional('v-card__title'))
Vue.component('VTableOverflow', createSimpleFunctional('v-table__overflow'))
Vue.component('VListItemActionText', createSimpleFunctional('v-list-item__action-text', 'span'))
Vue.component('VListItemContent', createSimpleFunctional('v-list-item__content', 'div'))
Vue.component('VListItemTitle', createSimpleFunctional('v-list-item__title', 'div'))
Vue.component('VListItemSubtitle', createSimpleFunctional('v-list-item__subtitle', 'div'))
Vue.component('VStepperHeader', createSimpleFunctional('v-stepper__header'))
Vue.component('VStepperItems', createSimpleFunctional('v-stepper__items'))
Vue.component('VToolbarTitle', createSimpleFunctional('v-toolbar__title'))
Vue.component('VToolbarItems', createSimpleFunctional('v-toolbar__items'))
Vue.directive("ClickOutside", ClickOutside)
Vue.directive("Color", Color)
Vue.directive("Intersect", Intersect)
Vue.directive("Mutate", Mutate)
Vue.directive("Resize", Resize)
Vue.directive("Ripple", Ripple)
Vue.directive("Scroll", Scroll)
Vue.directive("Touch", Touch)

import { render, staticRenderFns } from "./breadcrumbs.vue?vue&type=template&id=0c3cb417"
import script from "./breadcrumbs.vue?vue&type=script&lang=js"
export * from "./breadcrumbs.vue?vue&type=script&lang=js"
import style0 from "./breadcrumbs.vue?vue&type=style&index=0&id=0c3cb417&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default,VBreadcrumbs: require('/app/node_modules/vuetify/lib/components/VBreadcrumbs/VBreadcrumbs.js').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBreadcrumbs,VIcon})

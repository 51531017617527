export const state = () => ({
  allItems: [],
  nextPage: 1,
  lastPage: false,
  numbersItems: 0,
  balancesHistory: [],
  numbersHistory: 0,
  lastPageHistory: false,
  currentPageHistory: 1,
});

export const getters = {
  getItems: (state) => state.allItems,

  getLastPage: (state) => state.lastPage,

  getNumbersItems: (state) => state.numbersItems,

  getNumbersHistory: (state) => state.numbersHistory,

  getBalancesHistory: (state) => state.balancesHistory,

  getLastPageHistory: (state) => state.lastPageHistory,
};

export const mutations = {
  setItems: (state, items) => {
    state.allItems = items;
  },

  addItems: (state, items) => {
    for (let item of items) {
      state.allItems.push(item);
    }
  },

  setNumbersItems: (state, numbers) => {
    state.numbersItems = numbers;
  },

  setNextPage: (state, number) => {
    state.nextPage = number;
  },

  setLastPage: (state, value) => {
    state.lastPage = value;
  },

  setBalancesHistory: (state, items) => {
    state.balancesHistory = items;
  },

  setNumbersHistory: (state, numbers) => {
    state.numbersHistory = numbers;
  },

  setLastPageHistory: (state, value) => {
    state.lastPageHistory = value;
  },

  setCurrentPageHistory: (state, number) => {
    state.currentPageHistory = number;
  },

  addBalancesHistory: (state, items) => {
    state.balancesHistory = [...state.balancesHistory, ...items];
  },

  clearHistoryData: (state) => {
    state.balancesHistory = [];
    state.numbersHistory = 0;
    state.currentPageHistory = 1;
    state.lastPageHistory = false;
  },
};

export const actions = {
  async retrieveBalancesItems(
    { commit },
    payload = {
      page: 1,
    }
  ) {
    try {
      let {
        balances: items,
        total: numbers,
        page: currentPage,
        number_pages,
      } = await this.$axios.$get(`/api/balances`, {
        params: payload,
      });

      if (currentPage < number_pages) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setItems", items);
      commit("setNextPage", ++currentPage);
      commit("setNumbersItems", numbers);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },

  async retrieveNextItems({ state, commit }) {
    try {
      let {
        balances: items,
        total: numbers,
        page: currentPage,
        number_pages,
      } = await this.$axios.$get(`/api/balances?page=${state.nextPage}`);

      if (currentPage < number_pages) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("addItems", items);
      commit("setNextPage", ++currentPage);
      commit("setNumbersItems", numbers);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },

  async getBalancesHistory({ state, commit }, payload) {
    try {
      let { data } = await this.$axios.post(
        `/api/balances/transactions?page=${state.currentPageHistory}`,
        {
          user_id: payload,
        }
      );

      commit("setNumbersHistory", data.total);

      if (data.page < data.number_pages) {
        commit("setLastPageHistory", false);
      } else {
        commit("setLastPageHistory", true);
      }

      commit("setCurrentPageHistory", ++data.page);
      commit("setBalancesHistory", data.transactions);
    } catch (error) {
      console.log(error);
    }
  },

  async loadBalancesHistory({ state, commit }, payload) {
    try {
      let { data } = await this.$axios.post(
        `/api/balances/transactions?page=${state.currentPageHistory}`,
        {
          user_id: payload,
        }
      );

      if (data.page < data.number_pages) {
        commit("setLastPageHistory", false);
        commit("setCurrentPageHistory", ++data.page);
      } else {
        commit("setLastPageHistory", true);
      }

      commit("addBalancesHistory", data.transactions);
      commit("setNumbersHistory", data.total);
    } catch (error) {
      console.log(error);
    }
  },
};

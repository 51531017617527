export const state = () => ({
  allReviews: [],

  searchText: "",
  gender: null,
  district: null,

  page: 1,
  perPage: 20,
  total: 0,
  date_to: "",
  date_from: "",

  lastPage: Number.Infinity,
});

export const getters = {
  getAllReviews: (state) => state.allReviews,

  getSearchText: (state) => state.searchText,

  getLastPage: (state) => state.lastPage,

  getTotal: (state) => state.total,
};

export const mutations = {
  setReviews(state, reviews) {
    state.allReviews = reviews;
  },

  setSearchFilter(state, value) {
    state.searchText = value;
  },

  setDateFilter(state, value) {
    state.date_to = "";
    state.date_from = "";

    if (value.length === 1) {
      state.date_from = value[0];
    } else {
      state.date_from = value[0];
      state.date_to = value[1];
    }
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setPage(state, value) {
    state.page = value;
  },

  setTotal(state, value) {
    state.total = value;
  },

  setFilter(state, payload) {
    state[payload.key] = payload.value;
  },

  resetFilters(state) {
    state.searchText = "";
    state.gender = null;
    state.district = null;
  },

  setAdvertiserReviews(state, payload) {
    for (let i = 0; i < state.allReviews.length; i++) {
      if (state.allReviews[i].id === payload.id) {
        state.allReviews[i].new_messages = payload.new_messages;
        return;
      }
    }
  },
};

export const actions = {
  async retrieveReviews({ state, commit }, page) {
    try {
      const { data } = await this.$axios.get("/api/reviews", {
        params: {
          search: state.searchText,
          date_from: state.date_from,
          date_to: state.date_to,
          per_page: state.perPage,
          page: page || state.page,
        },
      });
      console.log(data);
      if (page) {
        commit("setReviews", data.data);
      } else {
        commit("setReviews", [...state.allReviews, ...data.data]);
      }
      commit("setPage", data.current_page + 1);
      commit("setTotal", data.total);
    } catch (error) {
      commit("setReviews", []);
      console.log(error);
    }
  },

  async readAdvertiserReviews(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "reviews",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },

  async getAllReviews({ state, commit, rootGetters }, page = 1) {
    try {
      const city = rootGetters["global/getCurrentCity"];
      const response = await this.$axios.get("/api/allreview", {
        params: {
          page,
          search: state.searchText,
          gender: state.gender,
          per_page: state.perPage,
          city: city.id,
          district: state.district,
        },
      });

      const reviews =
        page === 1
          ? response.data.data
          : [...state.allReviews, ...response.data.data];

      commit("setReviews", reviews);
      commit("setLastPage", response.data.last_page);
      commit("setTotal", response.data.total);
    } catch (error) {
      console.log(error);
    }
  },
};

export const state = () => ({
  reports: [],

  lastPage: false,
  reportsNumber: 0,
  nextPage: 1,

  sortedParams: {
    orderDirection: "desc",
    orderName: "created_at",
  },

  searchText: "",
});

export const getters = {
  getReports: (state) => state.reports,

  getLastPage: (state) => state.lastPage,

  getReportsNumber: (state) => state.reportsNumber,

  getOrderDirection: (state) => state.sortedParams.orderDirection,

  getOrderName: (state) => state.sortedParams.orderName,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setReports(state, data) {
    state.reports = data;
  },

  setNextPage(state, value) {
    state.nextPage = value;
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setReportsNumber(state, number) {
    state.reportsNumber = number;
  },

  setOrderDirection(state, value) {
    state.sortedParams.orderDirection = value;
  },

  setOrderName(state, value) {
    state.sortedParams.orderName = value;
  },

  setSearchText(state, text) {
    state.searchText = text;
  },
};

export const actions = {
  async retrieveReports({ state, commit }, resetPage = false) {
    if (resetPage) commit("setNextPage", 1);

    try {
      const { data } = await this.$axios.get("/api/manager/profiles/reports", {
        params: {
          per_page: 100,
          page: state.nextPage,
          order: `${state.sortedParams.orderName}-${state.sortedParams.orderDirection}`,
          search: state.searchText,
        },
      });

      if (data.page < data.number_pages) {
        commit("setLastPage", false);
        commit("setNextPage", state.nextPage + 1);
      } else {
        commit("setLastPage", true);
      }

      commit("setReportsNumber", data.total);
      commit("setReports", data.reports);
    } catch (error) {
      commit("setReports", []);
      console.log(error);
    }
  },
};

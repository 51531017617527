export const state = () => ({
  categoryList: [],
  isFetching: false,

  catalogPageRequestData: {
    category: "",
    subcategory: "",
  },

  openCategoriesFilter: false,
  sortBy: null,
});

export const getters = {
  getCategory: (state) => state.categoryList,

  getCategoryStructuredByGender: (state) => {
    const categoryStructured = { male: [], female: [], transgender: [] };
    state.categoryList.forEach((category) => {
      const categoryUrlPath = Object.keys(category?.child)[0];
      let genderKey = categoryUrlPath?.split("/")[1];
      if (genderKey) {
        genderKey = genderKey.replace("shemale", "transgender");
        categoryStructured[genderKey].push({
          title: category?.name,
          subcategories: Object.keys(category.child).map((key) => {
            return {
              title: category.child[key],
              url: key.replace("shemale", "transgender"),
            };
          }),
        });
      }
    });
    return categoryStructured;
  },

  getModal: (state) => state.openCategoriesFilter,

  getIsFetching: (state) => state.isFetching,

  getCatalogPageRequestData: (state) => state.catalogPageRequestData,
};

export const mutations = {
  setCategoriesFilter(state) {
    state.openCategoriesFilter = !state.openCategoriesFilter;
  },

  setCategory(state, category) {
    state.categoryList = category;
  },

  setIsFetching(state, isFetching) {
    state.isFetching = isFetching;
  },

  setCatalogPageRequestData(state, payload) {
    state.catalogPageRequestData = payload;
  },
};

export const actions = {
  async retrieveCategory({ commit }) {
    try {
      commit("setIsFetching", true);
      const { data } = await this.$axios.get("/api/category");

      commit("setCategory", data);
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsFetching", false);
    }
  },
};

const demoNotification = {
  type: "success",
  message: "This is a success message",
};

const defaultNotificationOptions = {
  type: "info",
  timeout: 5000,
  dismissible: true,
};

export const state = () => ({
  /**
   * Number of notifications to show at a time on display.
   */
  notificationCountToShow: 5,

  /**
   * Notification position
   *
   * @type {String}
   * @default 'top-right'
   * @enum 'top-right', 'bottom-right' - is done | 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left' - wip
   * */
  position: "bottom-right",

  /**
   * Example of a notification object:
   *
   * {
   *  id: 1,
   *  type: 'success', // 'success', 'error', 'info', 'warning'
   *  message: 'This is a success message',
   *  timeout: 6000,
   *  dismissible: true,
   *  position: 'top-right'
   * }
   */
  notifications: [],
});

export const getters = {
  getNotificationCountToShow(state) {
    return state.notificationCountToShow;
  },

  getNotificationPosition(state) {
    return state.position;
  },

  getAllNotifications(state) {
    return state.notifications;
  },

  getNotificationsToShow(state) {
    return state.notifications.slice(0, state.notificationCountToShow);
  },
};

export const mutations = {
  setNotificationCountToShow(state, count) {
    state.notificationCountToShow = count;
  },

  addNotification(state, notification = demoNotification) {
    let n = { ...defaultNotificationOptions, ...notification };
    if (!n.id) n.id = Math.floor(1000 + Math.random() * 9000);
    state.notifications.push(n);
  },

  removeNotification(state, notification) {
    state.notifications = state.notifications.filter(
      (n) => n.id !== notification.id
    );
  },
};

export const actions = {
  addNotification({ commit }, notification) {
    commit("addNotification", notification);
  },

  removeNotification({ commit }, notification) {
    commit("removeNotification", notification);
  },
};

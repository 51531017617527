export const state = () => ({
  allTariffs: [],
  detailsData: [],
});

export const getters = {
  getTariffs: (state) => state.allTariffs,

  getDetails: (state) => state.detailsData,
};

export const mutations = {
  setTariffs(state, items) {
    state.allTariffs = items;
  },

  setDetailsData(state, items) {
    state.detailsData = items;
  },
};

export const actions = {
  async retrieveTariffs({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/tariff/manager/get");

      commit("setTariffs", data.tariff);
      console.log(data);
    } catch (error) {
      commit("setTariffs", []);
      console.log(error);
    }
  },

  async retrieveDetailsTariff({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/get_tariffs_actions_list");

      commit("setDetailsData", data);
      console.log(data);
    } catch (error) {
      commit("setDetailsData", []);
      console.log(error);
    }
  },
};

import { render, staticRenderFns } from "./select-control.vue?vue&type=template&id=b5254654"
import script from "./select-control.vue?vue&type=script&lang=js"
export * from "./select-control.vue?vue&type=script&lang=js"
import style0 from "./select-control.vue?vue&type=style&index=0&id=b5254654&prod&lang=scss"
import style1 from "./select-control.vue?vue&type=style&index=1&id=b5254654&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VSelect: require('/app/node_modules/vuetify/lib/components/VSelect/VSelect.js').default,FormControl: require('/app/components/ui/form-control/form-control.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})

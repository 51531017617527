export const state = () => ({});

export const getters = {
  getHeaderNav: () => {
    return [
      {
        id: 1,
        name: "Live feed",
        link: "/escorts-updates",
        isShowMobile: true,
      },
      {
        id: 2,
        name: "Reviews",
        link: "/reviews",
        isShowMobile: true,
      },
      {
        id: 3,
        name: "Video",
        link: "/escorts-videos",
        isShowMobile: true,
      },
      {
        id: 4,
        name: "Photo",
        link: "/escorts-photo-gallery",
        isShowMobile: true,
      },
      {
        id: 5,
        name: "News",
        link: "/escorts-news",
        isShowMobile: true,
      },
      {
        id: 6,
        name: "Categories",
        link: "/categories",
        isShowMobile: true,
      },
    ];
  },

  getHeaderGenderNav: (state, getters, rootState, rootGetters) => {
    const catalogUrl = rootGetters["global/getCurrentLocationUrl"];

    return {
      id: 7,
      name: "Catalog",
      link: `/${catalogUrl}/female`,
      isShowMobile: false,
      subMenu: [
        { id: 96, name: "Female", link: `/${catalogUrl}/female` },
        { id: 97, name: "Shemale", link: `/${catalogUrl}/transgender` },
        { id: 98, name: "Male", link: `/${catalogUrl}/male` },
        { id: 99, name: "All", link: "/" + catalogUrl },
      ],
    };
  },
};

export const mutations = {};

export const actions = {};

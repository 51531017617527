export const state = () => ({
  usersList: [],
  numberItems: 0,
  lastPage: false,
  currentPage: 1,

  role_id: "",
  orderParam: {
    value: "id",
    direction: "desc",
  },

  searchText: "",
});

export const getters = {
  getUsers: (state) => state.usersList,

  getLastPage: (state) => state.lastPage,

  getNumber: (state) => state.numberItems,

  getOrderDirection: (state) => state.orderParam.direction,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setUsers(state, users) {
    state.usersList = users;
  },

  setUploadUsers(state, users) {
    state.usersList = [...state.usersList, ...users];
  },

  setCurrentPage(state, value) {
    state.currentPage = value;
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setNumberItems(state, value) {
    state.numberItems = value;
  },

  setRoleId(state, value) {
    state.role_id = value;
  },

  setOrederValue(state, value) {
    state.orderParam.value = value;
  },

  setOrderDirection(state, value) {
    state.orderParam.direction = value;
  },

  setUserRole(state, value) {
    state.role_id = value;
  },

  setSearchText(state, text) {
    state.searchText = text;
  },
};

export const actions = {
  async retrieveUsersList({ state, commit }) {
    commit("setCurrentPage", 1);

    try {
      const { data } = await this.$axios.get(
        `/api/manager/users?per_page=100&role_id=${state.role_id}&page=${state.currentPage}&order=${state.orderParam.value}-${state.orderParam.direction}&search=${state.searchText}`
      );
      console.log(data);

      commit("setNumberItems", data.total);

      if (data.page < data.number_pages) {
        commit("setCurrentPage", state.currentPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setUsers", data.users);
    } catch (error) {
      commit("setUsers", []);
      console.log(error);
    }
  },

  async uploadUsersList({ state, commit }) {
    try {
      const { data } = await this.$axios.get(
        `/api/manager/users?per_page=100&role_id=${state.role_id}&page=${state.currentPage}&order=${state.orderParam.value}-${state.orderParam.direction}&search=${state.searchText}`
      );
      console.log(data);

      commit("setNumberItems", data.total);

      if (data.page < data.number_pages) {
        commit("setCurrentPage", state.currentPage + 1);
      } else {
        commit("setLastPage", true);
      }

      commit("setUploadUsers", data.users);
    } catch (error) {
      commit("setUploadUsers", []);
      console.log(error);
    }
  },
};

export const state = () => ({
  allItems: [],
  nextPage: 1,
  lastPage: false,
  numbersItems: 0,
  loading: false,
});

export const getters = {
  getItems: (state) => state.allItems,

  getLastPage: (state) => state.lastPage,

  getNumbersItems: (state) => state.numbersItems,

  getLoading: (state) => state.loading,
};

export const mutations = {
  setItems: (state, items) => {
    state.allItems = items;
  },

  addItems: (state, items) => {
    for (let item of items) {
      state.allItems.push(item);
    }
  },

  setNumbersItems: (state, numbers) => {
    state.numbersItems = numbers;
  },

  setNextPage: (state, number) => {
    state.nextPage = number;
  },

  setLastPage: (state, value) => {
    state.lastPage = value;
  },

  setLoading: (state, value) => {
    state.loading = value;
  },
};

export const actions = {
  async retrieveItems({ commit }, payload = "new-photos-block") {
    try {
      if (payload.includes("new")) {
        payload = "";
      } else {
        payload = payload.split("-")[0];
      }

      commit("setLoading", true);

      let {
        profiles_photo: items,
        page: currentPage,
        total: numbers,
        number_pages,
      } = await this.$axios.$get(
        `/api/photos/manager/get${!payload.length ? "" : "/" + payload}?page=1`
      );

      if (currentPage < number_pages) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setItems", items);
      commit("setLoading", false);
      commit("setNextPage", ++currentPage);
      commit("setNumbersItems", numbers);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },

  async retrieveNextItems({ state, commit }, payload) {
    try {
      let {
        profiles_photo: items,
        page: currentPage,
        total: numbers,
        number_pages,
      } = await this.$axios.$get(
        `/api/photos/manager/get${
          payload && payload.length ? "/" + payload : ""
        }`,
        {
          params: {
            page: state.nextPage,
          },
        }
      );

      if (currentPage < number_pages) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("addItems", items);
      commit("setNextPage", ++currentPage);
      commit("setNumbersItems", numbers);
    } catch (error) {
      console.log(error);

      commit("setItems", []);
    }
  },
};

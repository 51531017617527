export default async (to, from, savedPosition) => {
      if (to.hash) {
        if (document.querySelector(to.hash)) {
          // mobile 30 desktop 60
          const yOffest = window.innerWidth < 768 ? 30 : 65;
          let position = {
            selector: to.hash,
            behavior: "smooth",
            offset: { x: 0, y: yOffest }
          };
          return position;
        }
        return false;
      }
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    }

export default function ({ app, route, redirect }) {
  if (!process.env.DEV_ACCESS_VALUE) {
    return;
  }
  if (
    route.path === "/go" &&
    route.params &&
    route.query.key === process.env.DEV_ACCESS_VALUE
  ) {
    app.$cookies.set("dev-access", process.env.DEV_ACCESS_VALUE, {
      path: "/",
      maxAge: 2147483647,
    });
    return redirect("/");
  }

  if (app.$cookies.get("dev-access") != process.env.DEV_ACCESS_VALUE) {
    return redirect("/maintenance");
  }
}

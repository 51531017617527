
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      notifications: "notifications/getSidebarNotifications",
      menu: "dashboardMenu/getMenuItems",
    }),

    // using to show available links in dashboard menu
    tariffActions() {
      return this.$store.state.auth.user.tariff_actions;
    },

    role() {
      return this.$store.state.auth.user.user_role;
    },

    menuItems() {
      return this.menu[this.role] || [];
    },

    querySlug() {
      return this.$route.query.slug;
    },
  },
  methods: {
    processLink(link) {
      if (link == "/dashboard/my-profiles" && this.querySlug) {
        return `${link}#${this.querySlug}`;
      }
      return link;
    },
  },
};

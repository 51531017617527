export default function ({ app, store }) {
  try {
    const currentCity = app.$cookies.get("current-city");
    if (currentCity && currentCity.id) {
      store.commit("global/setCurrentCity", currentCity);
    }
    const currentDistrict = app.$cookies.get("current-district");
    if (currentDistrict) {
      store.commit("global/setCurrentDistrict", currentDistrict);
    }
  } catch (e) {
    console.log(e);
  }
}

import { render, staticRenderFns } from "./advanced-tab.vue?vue&type=template&id=420c005a"
import script from "./advanced-tab.vue?vue&type=script&lang=js"
export * from "./advanced-tab.vue?vue&type=script&lang=js"
import style0 from "./advanced-tab.vue?vue&type=style&index=0&id=420c005a&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CheckboxControl: require('/app/components/ui/checkbox/checkbox-control.vue').default,SelectControl: require('/app/components/ui/select/select-control.vue').default,Checkbox: require('/app/components/ui/checkbox/checkbox.vue').default,FormControl: require('/app/components/ui/form-control/form-control.vue').default,InputControl: require('/app/components/ui/input/input-control.vue').default})


import HeaderV2 from "~/components/v2/common/header/header-v2.vue";

export default {
  name: "Slug",

  components: {
    HeaderV2,
  },

  middleware: ["isActive"],

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }
    // this.$root.$on("set-page-padding", () => {
    //   this.setPagePadding();
    // });
  },

  // methods: {
  // setPagePadding() {
  //   if (!this.$refs.header) return;
  //   this.headerHeight =
  //     this.$refs.header.$el.querySelector("header").clientHeight;
  // },
  // },
};

import { render, staticRenderFns } from "./text-search-modal.vue?vue&type=template&id=db037d6e"
import script from "./text-search-modal.vue?vue&type=script&lang=js"
export * from "./text-search-modal.vue?vue&type=script&lang=js"
import style0 from "./text-search-modal.vue?vue&type=style&index=0&id=db037d6e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconSearchWBg: require('/app/components/icons/IconSearchWBg.vue').default,InputControl: require('/app/components/ui/input/input-control.vue').default,CBtn: require('/app/components/ui/button/c-btn.vue').default,CModal: require('/app/components/ui/modal/c-modal.vue').default})

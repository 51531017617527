
export default {
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },

    statusModal: {
      type: Boolean,
      default: false,
    },

    advertiser: {
      type: Boolean,
      default: false,
    },

    inProfile: {
      type: Boolean,
      default: false,
    },

    mobProfile: {
      type: Boolean,
      default: false,
    },

    inOtherPages: {
      type: Boolean,
      default: false,
    },

    position: {
      type: String,
      default: null, // 'center' || 'top-300'
    },

    toManager: {
      type: Boolean,
      default: false,
    },

    prefilledText: {
      type: [String, null, undefined],
      default: "",
    },
  },

  data() {
    return {
      formAdvertiser: {
        to_user_id: null,
        text: "",
      },

      formModel: {
        text: "",
        profile_id: null,
      },

      valid: false,

      textRules: [
        (v) => !!v || "Message is required",
        // v => (v && v.length <= this.counterVal) || `Use a maximum ${this.counterVal} characters`,
      ],
    };
  },

  mounted() {
    if (this.advertiser) {
      this.formAdvertiser.to_user_id = this.profile?.user_id;
      if (this.prefilledText) {
        this.formAdvertiser.text = this.prefilledText;
      }
    } else {
      this.formModel.profile_id = this.profile?.id;
      if (this.prefilledText) {
        this.formModel = this.formAdvertiser;
      }
    }
  },

  methods: {
    async sendMessage() {
      if (this.advertiser) {
        if (this.$refs.messageForm.validate()) {
          try {
            if (this.toManager) {
              this.formAdvertiser.to_user_id = "manager";
            } else {
              this.formAdvertiser.to_user_id = this.profile.user_id;
            }
            const response = await this.$axios.post(
              "/api/messages/add",
              this.formAdvertiser
            );
            if (response.status === 200) {
              this.$root.$emit("new-message-is-added", response.data);

              this.$notify({
                type: "success",
                message: this.$t("message-sent-successfully"),
              });
            }
            this.$refs.messageForm.reset();
            this.closePm();
          } catch (error) {
            this.closePm();
            console.log(error);
          }
        }
      } else {
        if (this.$refs.messageForm.validate()) {
          try {
            if (this.toManager) {
              this.formModel.to_user_id = "manager";
            } else {
              this.formModel.profile_id = this.profile.id;
            }
            const response = await this.$axios.post(
              "/api/messages/add",
              this.formModel
            );
            if (response.status === 200) {
              this.$root.$emit("new-message-is-added", response.data);

              this.$notify({
                type: "success",
                message: this.$t("message-sent-successfully"),
              });
            }

            this.$refs.messageForm.reset();
            this.closePm();
          } catch (error) {
            console.log(error);
            this.closePm();
          }
        }
      }
    },

    closePm() {
      this.$emit("closePm");
      this.$refs.messageForm.reset();
      this.$refs.messageForm.resetValidation();
    },
  },
};
